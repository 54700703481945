export const GET_TESTINFO_HISTORY = 'GET_TESTINFO_HISTORY';
export const SET_TESTINFO_HISTORY = 'SET_TESTINFO_HISTORY';

export const GET_TESTINFOCOUNT_HISTORY = 'GET_TESTINFOCOUNT_HISTORY';
export const SET_TESTINFOCOUNT_HISTORY = 'SET_TESTINFOCOUNT_HISTORY';

export const GET_NAVIGATIONFILTER_HISTORY = 'GET_NAVIGATIONFILTER_HISTORY';
export const SET_NAVIGATIONFILTER_HISTORY = 'SET_NAVIGATIONFILTER_HISTORY';

export const GET_ANALYSISINFO_HISTORY = 'GET_ANALYSISINFO_HISTORY';
export const SET_ANALYSISINFO_HISTORY = 'SET_ANALYSISINFO_HISTORY';

export const GET_TESTDETAIL_HISTORY = 'GET_TESTDETAIL_HISTORY';
export const SET_TESTDETAIL_HISTORY = 'SET_TESTDETAIL_HISTORY';

export const SET_CURRENTQUESTION_HISTORY = 'SET_CURRENTQUESTION_HISTORY';

export const GET_FILTER_HISTORY = 'GET_FILTER_HISTORY';
export const SET_FILTER_HISTORY = 'SET_FILTER_HISTORY';

export const GET_STUDENTINFO_HISTORY = 'GET_STUDENTINFO_HISTORY';
export const SET_STUDENTINFO_HISTORY = 'SET_STUDENTINFO_HISTORY';

export const GET_STUDENTCOURSE_HISTORY = 'GET_STUDENTCOURSE_HISTORY';
export const SET_STUDENTCOURSE_HISTORY = 'SET_STUDENTCOURSE_HISTORY';

export const GET_INDIVIDUALFILTER_HISTORY = 'GET_INDIVIDUALFILTER_HISTORY';
export const SET_INDIVIDUALFILTER_HISTORY = 'SET_INDIVIDUALFILTER_HISTORY';

export const GET_NAMEFILTER_HISTORY = 'GET_NAMEFILTER_HISTORY';
export const SET_NAMEFILTER_HISTORY = 'SET_NAMEFILTER_HISTORY';
