import { createSelector } from 'reselect';

const getMaster = (state) => state?.master;

const getStandards = createSelector(getMaster, (master) => master?.standards);
const getDepartments = createSelector(getMaster, (master) => master?.departments);
const getMediums = createSelector(getMaster, (master) => master?.medium);
const getBoards = createSelector(getMaster, (master) => master?.boards);
const getTestType = createSelector(getMaster, (master) => master?.testType);
const getSubjects = createSelector(getMaster, (master) => master?.subjects);
const getUnits = createSelector(getMaster, (master) => master?.units);
const getTopics = createSelector(getMaster, (master) => master?.topics);
const getLevels = createSelector(getMaster, (master) => master?.levels);
const getTestStatuses = createSelector(getMaster, (master) => master?.testStatuses);
const getSchoolTypes = createSelector(getMaster, (master) => master?.schoolTypes);
const getExamList = createSelector(getMaster, (master) => master?.examList);
const getCourseList = createSelector(getMaster, (master) => master?.courseList);
const getClientList = createSelector(getMaster, (master) => master?.clientList);
const getCommonTestList = createSelector(getMaster, (master) => master?.commonTestList);
const getSubjectList = createSelector(getMaster, (master) => master?.subjectList);
const getExamRule = createSelector(getMaster, (master) => master?.examRule);
const getPersonList = createSelector(getMaster, (master) => master?.personList);
const getExams = createSelector(getMaster, (master) => master?.exams);
const getSubjectDetail = createSelector(getMaster, (master) => master?.subjectDetail);
const getQuestionCount = createSelector(getMaster, (master) => master?.questionCount);
const getQuestionType = createSelector(getMaster, (master) => master?.questionType);
const getQuestionPattern = createSelector(getMaster, (master) => master?.questionPattern);
const getClpt = createSelector(getMaster, (master) => master?.clpt);

export {
  getStandards,
  getDepartments,
  getMediums,
  getBoards,
  getTestType,
  getSubjects,
  getUnits,
  getTopics,
  getLevels,
  getTestStatuses,
  getSchoolTypes,
  getExamList,
  getCourseList,
  getClientList,
  getCommonTestList,
  getSubjectList,
  getExamRule,
  getPersonList,
  getExams,
  getSubjectDetail,
  getQuestionCount,
  getQuestionType,
  getQuestionPattern,
  getClpt
};
