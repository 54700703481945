import { memo, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDialog, CustomTextField } from 'components';
import Button from '@mui/material/Button';
import { v4 } from 'uuid';
import { getRefreshToken } from 'views/common/api/securityApi';
import { refreshToken as refreshTokenAction, login } from 'views/user/redux/actions';
import { loginApi } from 'views/user/api';
import * as CommonActions from 'app/redux/actions';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  count: `${PREFIX}-count`
};

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.count}`]: {
    paddingRight: 10
  }
}));

const COUNT_DOWN_TIMER_IN_HRS = 0;
const COUNT_DOWN_TIMER_IN_MINS = 5;
const COUNT_DOWN_TIMER_IN_SECS = 59;

function ExpiryDetail(props) {
  const { counter } = props;

  if (!counter) {
    return null;
  }

  return (
    <>
      {counter === 'expired' ? (
        <span className={classes.count}>Session expired</span>
      ) : (
        <span className={classes.count}>
          Session expires in <b>{`${counter?.mins}:${counter?.secs}`}</b>
        </span>
      )}
    </>
  );
}

function SessionUpdate() {
  const dispatch = useDispatch();

  let expiryCountdown;
  const [showSessionPanel, setShowSessionPanel] = useState(false);
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [counter, setCounter] = useState();
  const [dialogId, setDialogId] = useState();
  const firstName = useSelector((state) => state?.auth?.data?.FirstName ?? undefined);
  const clientIdentity = useSelector((state) => state?.auth?.data?.ClientUrl ?? undefined);
  // const exp = (new Date().getTime() + 6000) / 1000;
  const exp = useSelector((state) => state?.auth?.data?.exp ?? null);

  const executeCountDown = () => {
    const expUtc = exp * 1000;
    const now = new Date().getTime();
    const diff = expUtc - now;

    const hrs = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const secs = Math.floor((diff % (1000 * 60)) / 1000);

    // console.log(hrs, mins, secs);
    setCounter({
      mins: String(mins).padStart(2, '0'),
      secs: String(secs).padStart(2, '0')
    });

    if (
      hrs === COUNT_DOWN_TIMER_IN_HRS &&
      mins <= COUNT_DOWN_TIMER_IN_MINS &&
      secs <= COUNT_DOWN_TIMER_IN_SECS &&
      !showSessionPanel
    ) {
      setShowSessionPanel(true);
    }

    if (diff < 0) {
      setCounter('expired');
      if (!dialogId) {
        setDialogId(v4());
      }
    }

    return diff;
  };

  useEffect(() => {
    console.log(exp, counter);
    if (exp > 0 && !counter) {
      console.log('new interval');
      expiryCountdown = setInterval(() => {
        const diff = executeCountDown();
        if (diff < 0) {
          clearInterval(expiryCountdown);
        }
      }, 1000);
    }

    return () => clearInterval(expiryCountdown);
  }, [exp]);

  const handleToken = (e) => setToken(e.target.value);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const pristineThis = (closer) => {
    clearInterval(expiryCountdown);
    setShowSessionPanel(false);
    setCounter(undefined);

    if (closer) {
      setDialogId(undefined);
      dispatch(closer);
      setPassword('');
    }
  };

  const handleAuthorize = () => {
    const refreshToken = getRefreshToken();
    refreshToken.subscribe(
      (res) => {
        const token = res?.response?.token;
        if (token) {
          pristineThis();
          setTimeout(() => {
            dispatch(refreshTokenAction.set(token));
            dispatch(CommonActions.showAlert('Authorization successful', 'success'));
          }, 0);
        }
      },
      (err) => console.log(err)
    );
  };

  const handleLogin = (e, closer) => {
    if (!token || !password || !clientIdentity) {
      return;
    }

    const loginResponse = loginApi({
      userName: token,
      password,
      clientIdentity
    });
    loginResponse.subscribe(
      (res) => {
        const userData = res?.response;
        if (userData) {
          pristineThis(closer);
          setTimeout(() => {
            dispatch(login.set(userData));
            dispatch(CommonActions.showAlert('Login successful', 'success'));
          }, 0);
        }
      },
      (err) => {
        dispatch(CommonActions.showAlert(err?.response?.errorMessage, 'error'));
        e.preventDefault();
      }
    );
  };

  if (!showSessionPanel) {
    return null;
  }

  return (
    <Root>
      <ExpiryDetail counter={counter} />
      {counter && counter !== 'expired' && (
        <Button
          variant="contained"
          size="small"
          title="Your session going to expire, click here to authorize."
          onClick={handleAuthorize}
        >
          Authorize
        </Button>
      )}
      <CustomDialog
        id={dialogId}
        disableEscKey
        disableBackDrop
        disableAutoFocus
        handlePrimaryButtonClick={handleLogin}
        dialogTitle={<ExpiryDetail counter={counter} />}
        maxWidth="sm"
        dialogContent={`Hi ${firstName}, your session has beed expired. Please login again to continue.`}
        component={
          <>
            <CustomTextField
              autoFocus
              margin="dense"
              id="name"
              label="Email or Mobile"
              type="text"
              placeholder="Enter email or mobile"
              fullWidth
              value={token}
              onChange={handleToken}
            />
            <CustomTextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              placeholder="Enter password"
              fullWidth
              value={password}
              onChange={handlePassword}
            />
          </>
        }
        buttonPrimaryText={password?.length > 0 ? 'Login' : undefined}
      />
    </Root>
  );
}

export default memo(SessionUpdate);
