import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_TEST`,
  (type) => `SET_${type?.toUpperCase()}_TEST`,
  (type) => `POST_${type?.toUpperCase()}_TEST`,
  (type) => `PUT_${type?.toUpperCase()}_TEST`
);

const practice = properties('practice');
const personal = properties('personal');
const timer = properties('timer');
const selectedQuestion = properties('selectedQuestion');
const checkForReview = properties('checkForReview');
const answerSelected = properties('answerSelected');
const subjectiveAnswer = properties('subjectiveAnswer');
const multiCorrect = properties('multiCorrect');
const save = properties('save');
const finish = properties('finish');
const common = properties('common');
const resume = properties('resume');
const ping = properties('ping');

export {
  practice,
  personal,
  timer,
  selectedQuestion,
  checkForReview,
  answerSelected,
  subjectiveAnswer,
  multiCorrect,
  save,
  finish,
  common,
  resume,
  ping
};
