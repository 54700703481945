import produce from 'immer';
import * as Types from './types';

const defaultState = {
  documentDetails: {
    documentFilters: {
      page: 1,
      pageSize: 20,
      sorts: '-createdAt',
      filters: ''
    },
    count: 1,
    documents: []
  },
  questionDetails: {
    questionFilters: {
      page: 1,
      pageSize: 20,
      sort: '',
      filters: ''
    },
    filter: {
      classId: -1,
      mediumId: -1,
      subjectId: -1,
      questionLevel: -1,
      unitId: -1
    },
    count: 1,
    questions: []
  },
  commonTestDetails: {
    commonTestFilters: {
      page: 1,
      pageSize: 20,
      sorts: '-createdAt',
      filters: 'isActive==true'
    },
    filter: {
      isActive: true,
      examId: -1,
      createdBy: -1,
      updatedBy: -1
    },
    count: 1
  }
};

const createTest = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_CREATETEST_DOCUMENT_DATA:
      state.documentDetails.documents = action.payload;
      return state;
    case Types.SET_CREATETEST_DOCUMENTCOUNT_DATA:
      state.documentDetails.count = action.payload;
      return state;
    case Types.SET_CREATETEST_COURSE_DATA:
      state.courses = action.payload;
      return state;
    case Types.SET_CREATETEST_QUESTION_DATA:
      state.questionDetails.questions = action.payload;
      return state;
    case Types.SET_CREATETEST_QUESTIONCOUNT_DATA:
      state.questionDetails.count = action.payload;
      return state;
    case Types.SET_CREATETEST_COMMONTESTCOUNT_DATA:
      state.commonTestDetails.count = action.payload;
      return state;
    case Types.SET_CREATETEST_SUBJECT_DATA:
      state.subjects = action.payload;
      return state;
    case Types.SET_CREATETEST_UNIT_DATA:
      state.units = action.payload;
      return state;
    case Types.SET_CREATETEST_DOCUMENTFILTER_DATA:
      state.documentDetails.documentFilters = {
        ...state.documentDetails.documentFilters,
        ...action.payload
      };
      return state;
    case Types.SET_CREATETEST_QUESTIONFILTER_DATA:
      state.questionDetails.questionFilters = {
        ...state.questionDetails.questionFilters,
        ...action.payload
      };
      return state;
    case Types.SET_CREATETEST_FINDQUESTION_DATA:
      state.questionDetails.filter = {
        ...action.payload
      };
      return state;
    case Types.SET_CREATETEST_COMMONTESTFILTER_DATA:
      state.commonTestDetails.commonTestFilters = {
        ...state.commonTestDetails.commonTestFilters,
        ...action.payload
      };
      return state;
    case Types.SET_CREATETEST_TESTFILTERS_DATA:
      state.commonTestDetails.filter = {
        ...action.payload
      };
      return state;
    case Types.SET_CREATETEST_COMMONTEST_DATA: {
      // let newList = [];
      // if (action.payload?.testQus) {
      //   const questionList = [...action?.payload?.testQus];
      //   const orderItem = ['Physics', 'Chemistry', 'Botany', 'Zoology', 'Mathematics'];
      //   newList = questionList
      //     .sort((a, b) => orderItem.indexOf(a.subjectName) - orderItem.indexOf(b.subjectName))
      //     .map((question, index) => ({ ...question, questionKey: index }));
      // }
      // if (newList?.length > 0) {
      //   state.commonTest = {
      //     test: { ...state?.commonTest?.test, ...action?.payload?.test },
      //     testQus: [...newList],
      //   };
      // } else if (state?.commonTest?.testQus) {
      //   state.commonTest = {
      //     test: { ...action?.payload?.test },
      //     testQus: [...state?.commonTest?.testQus],
      //   };
      // } else {
      //   state.commonTest = { ...action.payload };
      // }
      // return state;
      if (action.payload?.questions?.length > 0) {
        const questionList = [...action.payload.questions];
        const orderItem = ['Physics', 'Chemistry', 'Botany', 'Zoology', 'Mathematics'];
        const newList = questionList
          .sort((a, b) => orderItem.indexOf(a.subjectName) - orderItem.indexOf(b.subjectName))
          .map((question, index) => ({ ...question, questionKey: index }));
        state.commonTest = {
          sections: action.payload.sections,
          test: action.payload.test,
          questions: [...newList]
        };
      } else {
        state.commonTest = { ...action.payload };
      }
      return state;
    }
    case Types.SET_CREATETEST_SELECTEDQUESTIONS_DATA:
      if (Object.keys(action.payload).length !== 0) {
        if (state?.selectedQuestions) {
          const questionList = [...state.selectedQuestions, { ...action.payload }];
          const orderItem = ['Physics', 'Chemistry', 'Botany', 'Zoology', 'Mathematics'];
          const newList = questionList
            .sort((a, b) => orderItem.indexOf(a.subjectName) - orderItem.indexOf(b.subjectName))
            .map((question, index) => ({ ...question, questionKey: index, sectionNo: 1 }));
          // state.selectedQuestions = [...state.selectedQuestions, { ...action.payload }];
          state.selectedQuestions = [...newList];
        } else state.selectedQuestions = [{ ...action.payload, questionKey: 0, sectionNo: 1 }];
      } else {
        state.selectedQuestions = [];
      }
      return state;
    case Types.SET_CREATETEST_REMOVEQUESTIONS_DATA: {
      const filteredArray = state?.selectedQuestions.filter(
        (selectedQuestion) => selectedQuestion.id !== action.payload.id
      );
      state.selectedQuestions = filteredArray;
      return state;
    }
    case Types.SET_CREATETEST_CLEARQUESTIONS_DATA:
      state.selectedQuestions = [];
      return state;
    case Types.SET_CREATETEST_SELECTEDQUESTION_DATA:
      state.selectedQuestion = action.payload;
      return state;
    case Types.SET_CREATETEST_SECTIONUPDATE_DATA:
      state.selectedQuestions[action.payload.index].sectionNo = action.payload.checked ? 2 : 1;
      return state;
    case Types.SET_CREATETEST_QUESTIONLIST_DATA: {
      const questionList = [...action.payload];
      const orderItem = ['Physics', 'Chemistry', 'Botany', 'Zoology', 'Mathematics'];
      const newList = questionList
        .sort((a, b) => orderItem.indexOf(a.subjectName) - orderItem.indexOf(b.subjectName))
        .map((question, index) => ({ ...question, questionKey: index, sectionNo: 1 }));
      state.selectedQuestions = [...newList];
      return state;
    }
    case Types.SET_CREATETEST_NEWEXAM_DATA:
      state.newExam = action.payload;
      return state;
    case Types.SET_CREATETEST_TESTQUESTION_DATA: {
      const filteredArray = state?.commonTest.testQus.filter(
        (selectedQuestion) => selectedQuestion.id !== action.payload.id
      );
      state.commonTest.questions = filteredArray;
      return state;
    }
    case Types.SET_CREATETEST_COMMONTESTLIST_DATA:
      state.commonTestList = action.payload;
      return state;
    default:
      return state;
  }
}, defaultState);

export { createTest };
