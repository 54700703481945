// @mui/icons-material
import Dashboard from 'assets/img/module/dashboard.png';
import History from 'assets/img/module/history.png';
import Exam from 'assets/img/module/exam.png';
import CommonTest from 'assets/img/module/commontest.png';

const staffEntryRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard
  },
  {
    path: '/createTest',
    name: 'Create Test',
    icon: Exam
  },
  {
    path: '/commonTest',
    name: 'Common Test',
    icon: CommonTest
  },
  {
    path: '/history',
    name: 'History',
    icon: History
  }
];

export default staffEntryRoutes;
