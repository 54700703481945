import { lazy } from 'react';

export const CourseConfig = {
  routes: [
    {
      path: '/course',
      exact: true,
      component: lazy(() => import('./sections/courseTab'))
    }
  ]
};
