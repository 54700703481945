import * as Types from 'views/common/redux/types';
import { PropMapper } from '@helpers/utilities';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_FOR_LOGOUT`,
  (type) => `SET_${type?.toUpperCase()}_FOR_LOGOUT`
);

const flag = properties('flag');

export { flag };
