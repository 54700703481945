export const SET_SUBSCRIPTION_COURSEINFO = 'SET_SUBSCRIPTION_COURSEINFO';
export const GET_SUBSCRIPTION_COURSEINFO = 'GET_SUBSCRIPTION_COURSEINFO';

export const SET_SUBSCRIPTION_SINGLECOURSEINFO = 'SET_SUBSCRIPTION_SINGLECOURSEINFO';
export const GET_SUBSCRIPTION_SINGLECOURSEINFO = 'GET_SUBSCRIPTION_SINGLECOURSEINFO';

export const SET_SUBSCRIPTION_FREESUBSCRIPTIONINFO = 'SET_SUBSCRIPTION_FREESUBSCRIPTIONINFO';
export const GET_SUBSCRIPTION_FREESUBSCRIPTIONINFO = 'GET_SUBSCRIPTION_FREESUBSCRIPTIONINFO';
export const POST_SUBSCRIPTION_FREESUBSCRIPTIONINFO = 'POST_SUBSCRIPTION_FREESUBSCRIPTIONINFO';

export const SET_SUBSCRIPTION_PURCHASECOURSEINFO = 'SET_SUBSCRIPTION_PURCHASECOURSEINFO';
export const GET_SUBSCRIPTION_PURCHASECOURSEINFO = 'GET_SUBSCRIPTION_PURCHASECOURSEINFO';
export const POST_SUBSCRIPTION_PURCHASECOURSEINFO = 'POST_SUBSCRIPTION_PURCHASECOURSEINFO';

export const SET_SUBSCRIPTION_CAPTUREPAYMENTINFO = 'SET_SUBSCRIPTION_CAPTUREPAYMENTINFO';
export const GET_SUBSCRIPTION_CAPTUREPAYMENTINFO = 'GET_SUBSCRIPTION_CAPTUREPAYMENTINFO';
export const POST_SUBSCRIPTION_CAPTUREPAYMENTINFO = 'POST_SUBSCRIPTION_CAPTUREPAYMENTINFO';

export const GET_SUBSCRIPTION_SUBSCRIBEDINFO = 'GET_SUBSCRIPTION_SUBSCRIBEDINFO';
export const SET_SUBSCRIPTION_SUBSCRIBEDINFO = 'SET_SUBSCRIPTION_SUBSCRIBEDINFO';

export const GET_SUBSCRIPTION_NONSUBSCRIBEDINFO = 'GET_SUBSCRIPTION_NONSUBSCRIBEDINFO';
export const SET_SUBSCRIPTION_NONSUBSCRIBEDINFO = 'SET_SUBSCRIPTION_NONSUBSCRIBEDINFO';
