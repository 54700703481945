import { memo } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function CustomRadioGroup(props) {
  const { labelText, radioGroups, onRadioChange, formError, helperText, selectedValue } = props;
  const handleChange = (event) => {
    onRadioChange(event);
  };
  return (
    <>
      <FormControl component="fieldset" error={formError}>
        <FormLabel component="legend">{labelText}</FormLabel>
        <RadioGroup
          aria-label={labelText}
          name={labelText}
          value={selectedValue}
          onChange={handleChange}
        >
          {radioGroups.map((radioGroup, index) => (
            <FormControlLabel
              key={index}
              id={index}
              value={radioGroup.value}
              control={<Radio color={radioGroup.colorType} />}
              label={radioGroup.labelText}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

export default memo(CustomRadioGroup);
