import { memo } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const root = {
  '& > div': {
    borderRadius: '15px'
  },
  '& div div': {
    padding: '13px 14px'
  }
};

const selectStyle = {
  '& > div': {
    whiteSpace: 'unset'
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function CustomSelect(props) {
  const {
    dropdownValues,
    selectedValue,
    onDropdownChange,
    customStyle,
    multiple,
    fullWidth,
    ...rest
  } = props;

  const handleValueChange = (e) => {
    if (onDropdownChange) onDropdownChange(e);
  };

  if (multiple) {
    return (
      <FormControl fullWidth>
        <InputLabel>{rest.label}</InputLabel>
        <Select
          multiple
          value={selectedValue}
          onChange={handleValueChange}
          sx={{ ...root, ...selectStyle }}
          {...rest}
          renderValue={(selected) =>
            selected.map((value) => (
              <Chip
                color="primary"
                sx={{ margin: '3px 3px', padding: '5px !important', justifyContent: 'flex-start' }}
                key={value}
                label={dropdownValues?.find((each) => each.value === value)?.label}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => {
                  if (onDropdownChange) {
                    onDropdownChange({
                      target: { value: selectedValue?.filter((each) => each !== value) }
                    });
                  }
                }}
              />
            ))
          }
          MenuProps={MenuProps}
        >
          {dropdownValues.map((dropdownValue, index) => (
            <MenuItem key={index} value={dropdownValue.value}>
              {dropdownValue.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <TextField
      select
      fullWidth
      {...rest}
      value={selectedValue}
      onChange={handleValueChange}
      sx={{ ...root }}
    >
      {dropdownValues?.map((dropdownValue, index) => (
        <MenuItem key={index} value={dropdownValue.value}>
          {dropdownValue.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default memo(CustomSelect);
