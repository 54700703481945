import { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import { CustomCheckBox, CustomTextField } from 'components';
import { availabilityEmail, availabilityPhone } from 'views/user/redux/actions';
import { getEmailAvailability, getPhoneAvailability } from 'views/user/redux/selectors';
import * as CommonActions from 'app/redux/actions';
import { requestContactChange, studentInfo } from 'views/profile/redux/actions';
import { getProfileOtpData, getUserInfo } from 'views/profile/redux/selectors';
import { examList, medium, standard } from 'views/common/redux/actions';
import { getExamList, getMediums, getStandards } from 'views/common/redux/selectors';
import { stringify } from 'query-string';
import OtpPage from './otpPage';

function ProfileVerification() {
  const dispatch = useDispatch();
  const user = useSelector(getUserInfo);
  const clientId = useSelector((state) => state?.auth?.data?.ClientId);
  const studentId = +useSelector((state) => state?.auth?.data?.StudentId);
  const isEmailTokenAvailable = useSelector(getEmailAvailability);
  const isPhoneTokenAvailable = useSelector(getPhoneAvailability);
  const phoneRegex = /[^0-9]/gi;
  const emailRegex = /\S+@\S+\.\S+/;
  const examLists = useSelector(getExamList);
  const mediums = useSelector(getMediums);
  const standards = useSelector(getStandards);
  const [email, setEmail] = useState(emailRegex.test(user?.emailId) ? user?.emailId : '');
  const [mobile, setMobile] = useState(/^[0-9]+$/.test(user?.mobileNo) ? user?.mobileNo : '');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [phoneHelperText, setPhoneHelperText] = useState('');
  const [flow, setFlow] = useState('');
  const [isOtpSent, setOtpSent] = useState(false);
  const [examInterest, setExamInterest] = useState([]);
  const [selectedMedium, setSelectedMedium] = useState(1);
  const [selectedStandard, setSelectedStandard] = useState(0);
  const otpData = useSelector(getProfileOtpData);

  useEffect(() => {
    if (!isEmailTokenAvailable) {
      setIsValidEmail(false);
      setEmailHelperText('Email already exist !');
    } else {
      setIsValidEmail(true);
      setEmailHelperText('');
    }
    if (!isPhoneTokenAvailable) {
      setIsValidPhone(false);
      setPhoneHelperText('Mobile already exist !');
    } else {
      setIsValidPhone(true);
      setPhoneHelperText('');
    }
  }, [isEmailTokenAvailable, isPhoneTokenAvailable]);

  useEffect(() => {
    dispatch(examList.get());
    dispatch(medium.get());
    dispatch(standard.get());
  }, []);

  useEffect(() => {
    if (otpData?.id) setOtpSent(true);
  }, [otpData]);

  const handleEmailOnBlur = (e) => {
    const rEx = /\S+@\S+\.\S+/;
    const isValidEmail = rEx.test(e.target.value);
    if (!isValidEmail) {
      setIsValidEmail(isValidEmail);
      setEmailHelperText('Enter valid email address');
    } else {
      dispatch(CommonActions.startLoading());
      dispatch(
        availabilityEmail.get(stringify({ userName: e.target.value, clientId }, { skipNull: true }))
      );
    }
  };

  const handleEmailOnFocus = () => {
    dispatch(availabilityEmail.set(true));
    setIsValidEmail(true);
  };

  const handleMobileOnBlur = (e) => {
    const isValidMobile = e.target.value.length === 10;
    if (!isValidMobile) {
      setIsValidPhone(isValidMobile);
      setPhoneHelperText('Enter Valid 10 digit Mobile number');
    } else {
      dispatch(CommonActions.startLoading());
      dispatch(
        availabilityPhone.get(stringify({ userName: e.target.value, clientId }, { skipNull: true }))
      );
    }
  };

  const handleMobileOnFocus = () => {
    dispatch(availabilityPhone.set(true));
    setIsValidPhone(true);
  };

  const handleChanges = (name, value) => {
    if (name === 'emailId') setEmail(value);
    else if (name === 'mobileNo') setMobile(value);
  };

  const handleEmailAdd = () => {
    setFlow('email');
    dispatch(CommonActions.startLoading());
    dispatch(requestContactChange.post({ isEmail: true, emailId: email }));
  };

  const handleMobileAdd = () => {
    setFlow('mobile');
    dispatch(CommonActions.startLoading());
    dispatch(requestContactChange.post({ isEmail: false, mobileNo: mobile }));
  };

  const onOtpSubmit = () => {
    dispatch(requestContactChange.set({}));
  };

  const onChangeEvent = (selectedId, isSelected) => {
    if (isSelected) {
      setExamInterest((prevStae) => [...prevStae, selectedId]);
    } else {
      setExamInterest((prevState) => prevState.filter((id) => id !== selectedId));
    }
  };

  const handleChangeMedium = (event) => {
    setSelectedMedium(+event.target.value);
  };

  const handleChangeStandard = (event) => {
    setSelectedStandard(+event.target.value);
  };

  const updateEnable = () => selectedMedium > 0 && selectedStandard > 0 && examInterest.length > 0;

  const onStudentUpdate = () => {
    if (selectedMedium !== -1 && selectedStandard !== -1 && examInterest.length > 0) {
      dispatch(CommonActions.startLoading());
      dispatch(
        studentInfo.post({
          mediumId: selectedMedium,
          classId: selectedStandard,
          examId: examInterest
        })
      );
    }
  };

  return (
    <Grid container spacing={2}>
      {!user?.isEmailVerified && (
        <Grid item xs={12} container spacing={2}>
          <Grid item>
            <Typography variant="subtitle2">Verify your Email</Typography>
          </Grid>
          <Grid item xs={10} sm={10}>
            <CustomTextField
              label="Email"
              required
              error={!isValidEmail}
              helperText={!isValidEmail && emailHelperText}
              variant="outlined"
              disabled={isOtpSent}
              placeholder="Email Id"
              fullWidth
              value={email}
              onChange={(e) => handleChanges('emailId', e.target.value)}
              onBlur={handleEmailOnBlur}
              onFocus={handleEmailOnFocus}
            />
          </Grid>
          <Grid item container alignItems="center" xs={2} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEmailAdd}
              disabled={isOtpSent}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
      {!user?.isMobileVerified && (
        <Grid item xs={12} container spacing={2}>
          <Grid item>
            <Typography variant="subtitle2">Verify your Mobile</Typography>
          </Grid>
          <Grid item xs={10} sm={10}>
            <CustomTextField
              label="Mobile"
              required
              error={!isValidPhone}
              helperText={!isValidPhone && phoneHelperText}
              disabled={isOtpSent}
              variant="outlined"
              placeholder="Mobile"
              fullWidth
              inputProps={{ pattern: /[^0-9]/gi }}
              value={mobile}
              onChange={(e) => {
                e.target.value = e.target.value.replace(phoneRegex, '');
                handleChanges('mobileNo', e.target.value);
              }}
              onBlur={handleMobileOnBlur}
              onFocus={handleMobileOnFocus}
            />
          </Grid>
          <Grid item contanier alignItems="center" xs={2} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleMobileAdd}
              disabled={isOtpSent}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
      {isOtpSent && (
        <Grid item xs={12} sm={12} md={12}>
          <OtpPage isVerify={false} isEmail={flow === 'email'} onSubmit={onOtpSubmit} />
        </Grid>
      )}
      {studentId === 0 && (
        <>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select your interested Exam (* Required)</FormLabel>
              <FormGroup row>
                {examLists?.map((examlistItem) => (
                  <CustomCheckBox
                    id={examlistItem.id}
                    key={examlistItem.id}
                    name={examlistItem.name}
                    title={examlistItem.name}
                    handleChecked={onChangeEvent}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select your medium (* Required)</FormLabel>
              <RadioGroup
                aria-label="medium"
                name="medium"
                value={selectedMedium}
                onChange={handleChangeMedium}
                row
              >
                {mediums?.map((medium) => (
                  <FormControlLabel
                    key={medium.id}
                    value={medium.id}
                    control={<Radio color="primary" />}
                    label={medium.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select your standard (* Required)</FormLabel>
              <RadioGroup
                aria-label="standard"
                name="standard"
                value={selectedStandard}
                onChange={handleChangeStandard}
                row
              >
                {standards?.map((standard) => (
                  <FormControlLabel
                    key={standard.id}
                    value={standard.id}
                    control={<Radio color="primary" />}
                    label={`Class ${standard.name}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={onStudentUpdate}
              disabled={!updateEnable}
            >
              Update
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default memo(ProfileVerification);
