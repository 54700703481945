import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CommonActions from 'app/redux/actions';
import { errorMapper } from '@helpers/utilities/errorHelper';

const defErrMap = (err) => {
  let defErrs = [CommonActions.errorResponse(err?.response ?? err), CommonActions.stopLoading()];

  defErrs = [
    ...defErrs,
    err?.status === 401
      ? CommonActions.setIsUserAuthorized(false)
      : (err?.response?.errors != null || err?.response?.errorMessage != null) &&
        CommonActions.showAlert(errorMapper(err?.response), 'error')
  ];

  return defErrs.filter((n) => n);
};

export const executeEpic = (type, from, successMap, errMap) => (action$) =>
  action$.pipe(
    ofType(type),
    mergeMap((action) =>
      from(action.payload).pipe(
        mergeMap((res) => of(...(successMap ? successMap(res, action.payload) : []))),
        catchError((err) =>
          of(...(errMap ? errMap(err, action.payload) : []), ...defErrMap(err, action.payload))
        )
      )
    )
  );
