import produce from 'immer';
import * as Types from './types';

const VALIDATE_USER_STATE = {
  isEmailTokenAvailable: true,
  isPhoneTokenAvailable: true
};
const validateUserName = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_USER_AVAILABILITYEMAIL:
      state.isEmailTokenAvailable = action.payload;
      return;
    case Types.SET_USER_AVAILABILITYPHONE:
      state.isPhoneTokenAvailable = action.payload;
      return;
    default:
      return state;
  }
}, VALIDATE_USER_STATE);

const CLIENT_DEFAULT_STATE = {};

const clientData = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_USER_CLIENT:
      return action.payload;
    default:
      return state;
  }
}, CLIENT_DEFAULT_STATE);

const USER_DEFAULT_STATE = { activeStep: 0 };
const userData = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_USER_LOGIN:
      state.user = action.payload;
      return state;
    case Types.SET_USER_REGISTER:
      state.registerData = action.payload;
      return state;
    case Types.SET_USER_OTP:
      state.otpData = action.payload;
      return state;
    case Types.SET_USER_REGISTERCOMPLETE:
      state.user = action.payload;
      return state;
    case Types.SET_USER_REFRESHTOKEN: {
      if (state?.user) {
        state.user.token = action.payload;
      } else {
        state.user = { token: action.payload };
      }
      return state;
    }
    case Types.SET_USER_FPACTIVESTEP:
      state.activeStep = action.payload;
      return state;
    case Types.SET_USER_FORGETPASSWORDINITIATE:
      state.forgetPasswordInitiate = action.payload;
      return state;
    case Types.SET_USER_FORGETPASSWORDOTP:
      state.fpOtpData = action.payload;
      return state;
    case Types.SET_USER_TESTOTP:
      state.testOtpData = action.payload;
      return state;
    default:
      return state;
  }
}, USER_DEFAULT_STATE);

export {
  // userRegistration,
  validateUserName,
  clientData,
  userData
};
