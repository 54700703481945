import { executeEpic } from '@helpers/utilities';
import { stopLoading, showAlert } from 'app/redux/actions';
import { push, replace } from 'connected-react-router';
import * as Types from './types';
import {
  getCourseApi,
  getDocumentApi,
  getQuestionApi,
  getSubjectApi,
  getUnitApi,
  getCommonTestApi,
  postCommonTestApi,
  putCommonTestApi,
  deleteCommonTestApi,
  putQuestionUpdateApi,
  getQuestionListApi,
  postTestQuestionApi,
  deleteTestQuestionApi,
  getCommonTestListApi,
  getCommonTestActivateApi
} from '../api';
import {
  course,
  document,
  question,
  subject,
  unit,
  documentCount,
  questionCount,
  questionList,
  commonTest,
  commonTestRemove,
  commonTestList,
  selectedQuestions,
  commonTestCount
} from './actions';

const getCourseEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_COURSE_DATA, getCourseApi, (responseData) => [
    stopLoading(),
    course.set(responseData.response)
  ])(action$);

const getDocumentEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_DOCUMENT_DATA, getDocumentApi, (responseData) => [
    document.set(responseData.response),
    documentCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10)),
    stopLoading()
  ])(action$);

const getQuestionEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_QUESTION_DATA, getQuestionApi, (responseData) => [
    question.set(responseData.response),
    questionCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10)),
    stopLoading()
  ])(action$);

const getSubjectListEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_SUBJECT_DATA, getSubjectApi, (responseData) => [
    subject.set(responseData.response)
  ])(action$);

const getUnitListEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_SUBJECT_DATA, getUnitApi, (responseData) => [
    unit.set(responseData.response)
  ])(action$);

const getCommonTestEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_COMMONTEST_DATA, getCommonTestApi, (responseData, payload) =>
    payload?.reassign
      ? [stopLoading(), commonTest.set(responseData.response)]
      : [stopLoading(), commonTest.set(responseData.response), replace('/createTest/edit')]
  )(action$);

const postCommonTestEpic = (action$) =>
  executeEpic(Types.POST_CREATETEST_COMMONTEST_DATA, postCommonTestApi, () => [
    stopLoading(),
    commonTest.set([]),
    // clearQuestions.set(),
    replace('/createTest'),
    showAlert('Common Test has been created successfully', 'success')
  ])(action$);

const putCommonTestEpic = (action$) =>
  executeEpic(Types.PUT_CREATETEST_COMMONTEST_DATA, putCommonTestApi, () => [
    stopLoading(),
    push('/createTest'),
    commonTest.set([]),
    showAlert('Common Test has been updated successfully', 'success')
  ])(action$);

const deleteCommonTestEpic = (action$) =>
  executeEpic(Types.DELETE_CREATETEST_COMMONTEST_DATA, deleteCommonTestApi, () => [
    stopLoading(),
    showAlert('Common Test has been deleted successfully', 'success'),
    commonTestList.get('filters=isActive==true&page=1&pageSize=20&sorts=-createdAt')
  ])(action$);

const putQuestionUpdateEpic = (action$) =>
  executeEpic(Types.PUT_CREATETEST_QUESTIONUPDATE_DATA, putQuestionUpdateApi, () => [
    stopLoading(),
    showAlert('Question has been updated successfully', 'success'),
    selectedQuestions.set({})
  ])(action$);

const getQuestionListEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_QUESTIONLIST_DATA, getQuestionListApi, (responseData) => [
    questionList.set(responseData.response),
    stopLoading()
  ])(action$);

const postTestQuestionEpic = (action$) =>
  executeEpic(Types.POST_CREATETEST_TESTQUESTION_DATA, postTestQuestionApi, (reponse, payload) => [
    stopLoading(),
    selectedQuestions.set([]),
    commonTest.get({ testId: payload.testId }),
    replace('/createTest/edit'),
    showAlert('Questions added successfully', 'success')
  ])(action$);

const deleteTestQuestionEpic = (action$) =>
  executeEpic(
    Types.DELETE_CREATETEST_TESTQUESTION_DATA,
    deleteTestQuestionApi,
    (response, payload) => [
      stopLoading(),
      showAlert('Question removed successfully', 'success'),
      commonTestRemove.get({ testId: payload.testId })
    ]
  )(action$);

const getCommonTestRemoveEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_COMMONTESTREMOVE_DATA, getCommonTestApi, (responseData) => [
    commonTest.set(responseData.response)
  ])(action$);

const getCommonTestListEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_COMMONTESTLIST_DATA, getCommonTestListApi, (responseData) => [
    stopLoading(),
    commonTestList.set(responseData.response),
    commonTestCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10))
  ])(action$);

const commonTestActivateEpic = (action$) =>
  executeEpic(Types.GET_CREATETEST_COMMONTESTACTIVATE_DATA, getCommonTestActivateApi, () => [
    stopLoading(),
    commonTestList.get('filters=isActive==true&page=1&pageSize=20&sorts=-createdAt'),
    showAlert('Common test has been activated successfully', 'success')
  ])(action$);

export default [
  getCourseEpic,
  getDocumentEpic,
  getQuestionEpic,
  getSubjectListEpic,
  getUnitListEpic,
  getCommonTestEpic,
  postCommonTestEpic,
  putCommonTestEpic,
  deleteCommonTestEpic,
  putQuestionUpdateEpic,
  getQuestionListEpic,
  postTestQuestionEpic,
  deleteTestQuestionEpic,
  getCommonTestRemoveEpic,
  getCommonTestListEpic,
  commonTestActivateEpic
];
