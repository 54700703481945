import { memo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const root = {
  '& > div': {
    borderRadius: '15px'
  },
  '& input': {
    height: '45px',
    padding: '0px 14px'
  },
  '& label': {
    height: '45px',
    top: '-3px'
  },
  '& label[data-shrink=true]': {
    top: 0
  }
};
// root: {
//   "& > div": {
//     borderRadius: 5,
//     //background: theme.palette.action.selected,
//   },
//   "& input": {
//     padding: "13px 30px",
//   },
//   "& label": {
//     //margin: "-5px",
//   },
// },

function CustomTextField(props) {
  const { customStyle, ...rest } = props;
  return (
    <TextField
      {...rest}
      // size="medium"
      // InputLabelProps={{ shrink: true }}
      sx={{ ...root }}
    />
  );
}

export default memo(CustomTextField);

CustomTextField.propTypes = {
  customStyle: PropTypes.any
};
