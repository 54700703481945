import { forwardRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as CommonActions from 'app/redux/actions';

const DEFAULT_HIDE_ALERT_TIME = 5000;

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function SimpleSnackbar() {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { show, data, type } = useSelector((state) => state?.naviraAlert);
  // const data = useSelector((state) => state.naviraAlert.data);

  useEffect(() => {
    const timer = show
      ? setTimeout(() => dispatch(CommonActions.hideAlert()), DEFAULT_HIDE_ALERT_TIME)
      : null;
    return () => clearTimeout(timer);
  }, [show]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(CommonActions.hideAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      // message={data}
      action={
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    >
      <Alert onClose={handleClose} severity={type}>
        {data}
      </Alert>
    </Snackbar>
  );
}
