import { replace } from 'connected-react-router';
import { executeEpic } from '@helpers/utilities';
import * as CommonActions from 'app/redux/actions';
import * as Types from './types';
import { studentUpload, client, staffUpload } from './actions';
import {
  postStudentBulkImportApi,
  postStudentDownloadExcelApi,
  getClientApi,
  postStaffBulkImportApi,
  postStaffDownloadExcelApi
} from '../api';

const clientUploadEpic = (action$) =>
  executeEpic(Types.GET_BULKIMPORT_CLIENT, getClientApi, (responseData) => [
    client.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const uploadStudentEpic = (action$) =>
  executeEpic(
    Types.POST_BULKIMPORT_STUDENTUPLOAD,
    postStudentBulkImportApi,
    (responseData) => [
      studentUpload.set(responseData.response),
      CommonActions.stopLoading(),
      replace('/user/confirmation/student')
    ],
    (errorResponse) => {
      if (errorResponse?.status === 422)
        return [
          CommonActions.stopLoading(),
          studentUpload.set(errorResponse.response),
          replace('/user/confirmation/student')
        ];
      return [];
    }
  )(action$);

const downloadStudentExcelEpic = (action$) =>
  executeEpic(
    Types.POST_BULKIMPORT_STUDENTDOWNLOADEXCEL,
    postStudentDownloadExcelApi,
    (responseData) => {
      const blob = new Blob([responseData.response], {
        type: responseData.xhr.getResponseHeader('content-type')
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = responseData.xhr.getResponseHeader('content-disposition');
      let fileName = '';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        // eslint-disable-next-line prefer-destructuring
        if (fileNameMatch.length >= 2) fileName = fileNameMatch[1];
      }
      if (fileName) {
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
      return [
        CommonActions.stopLoading(),
        CommonActions.showAlert('Document downloaded successfully', 'success')
      ];
    }
  )(action$);

const uploadStaffEpic = (action$) =>
  executeEpic(
    Types.POST_BULKIMPORT_STAFFUPLOAD,
    postStaffBulkImportApi,
    (responseData) => [
      staffUpload.set(responseData.response),
      CommonActions.stopLoading(),
      replace('/user/confirmation/staff')
    ],
    (errorResponse) => {
      if (errorResponse?.status === 422)
        return [
          CommonActions.stopLoading(),
          staffUpload.set(errorResponse.response),
          replace('/user/confirmation/staff')
        ];
      return [];
    }
  )(action$);

const downloadStaffExcelEpic = (action$) =>
  executeEpic(
    Types.POST_BULKIMPORT_STAFFDOWNLOADEXCEL,
    postStaffDownloadExcelApi,
    (responseData) => {
      const blob = new Blob([responseData.response], {
        type: responseData.xhr.getResponseHeader('content-type')
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = responseData.xhr.getResponseHeader('content-disposition');
      let fileName = '';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        // eslint-disable-next-line prefer-destructuring
        if (fileNameMatch.length >= 2) fileName = fileNameMatch[1];
      }
      if (fileName) {
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
      return [
        CommonActions.stopLoading(),
        CommonActions.showAlert('Document downloaded successfully', 'success')
      ];
    }
  )(action$);

export default [
  uploadStudentEpic,
  downloadStudentExcelEpic,
  clientUploadEpic,
  uploadStaffEpic,
  downloadStudentExcelEpic,
  downloadStaffExcelEpic
];
