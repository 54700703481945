import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from 'app/config/route-config';
import { CustomLoading } from 'components';
import { NaviraAlert } from '@helpers';
import * as CommonSelectors from 'app/redux/selectors';
import NaviraSuspense from '../NaviraSuspense/naviraSuspense';
import AuthLayout from './authLayout';

const ENTRY_ROUTES = ['/', '/login', '/signUp', '/signUpComplete', '/forgetPassword', '/fetchOtp'];

const renderRoutes = () => (
  <>
    {routes?.map((route, key) => {
      const { path, exact, component: OtherComponent } = route;
      return (
        <Route key={key} {...{ path, exact, key }}>
          <OtherComponent />
        </Route>
      );
    })}
  </>
);

function NaviraLayout() {
  // Store Selectors
  const locationPath = useSelector(CommonSelectors.getPath);

  if (ENTRY_ROUTES.includes(locationPath)) {
    // This block of code will execute only when path is equal to /login, /signUp and /
    return (
      <>
        <NaviraAlert />
        <CustomLoading />
        <NaviraSuspense>
          <Switch>{renderRoutes()}</Switch>
        </NaviraSuspense>
      </>
    );
  }
  /* This block of code will execute rest all other routes. 
    This is the place where we are our header, sidebar and footer components are loaded */
  return <AuthLayout />;
}

export default NaviraLayout;
