import { replace } from 'connected-react-router';
import { stopLoading, showAlert } from 'app/redux/actions';
import { executeEpic } from '@helpers/utilities';
import * as Types from './types';
import {
  question,
  questionList,
  questionDetailList,
  questionCount,
  questionFilter,
  translateQuestion
} from './actions';
import {
  getQuestion,
  postQuestion,
  putQuestion,
  getQuestions,
  getApproveQuestion,
  postQuestionImageApi,
  postAnswerImageApi,
  deleteQuestionImageApi,
  deleteAnswerImageApi,
  postRetunQuestion,
  deleteQuestionApi,
  getTranslateQuestionApi
} from '../api';

const uploadQuestionEpic = (action$) =>
  executeEpic(Types.POST_UPLOAD_QUESTION, postQuestion, (responseData) => [
    question.set(responseData.response),
    stopLoading(),
    replace('/upload'),
    showAlert('Question added successfully', 'success')
  ])(action$);

const updateQuestionEpic = (action$) =>
  executeEpic(Types.PUT_UPLOAD_QUESTION, putQuestion, (responseData) => [
    question.set(responseData.response),
    stopLoading(),
    questionFilter.set({ filters: '' }),
    replace('/upload'),
    showAlert('Question updated successfully', 'success')
  ])(action$);

const getQuestionEpic = (action$) =>
  executeEpic(Types.GET_UPLOAD_QUESTION, getQuestion, (responseData) => [
    question.set(responseData.response),
    stopLoading()
    // replace('/upload/review')
  ])(action$);

const deleteQuestionEpic = (action$) =>
  executeEpic(Types.DELETE_UPLOAD_QUESTION, deleteQuestionApi, () => [
    stopLoading(),
    replace('/upload')
  ])(action$);

const getQuestionsEpic = (action$) =>
  executeEpic(Types.GET_UPLOAD_QUESTIONLIST, getQuestions, (responseData) => [
    questionList.set(responseData.response),
    questionCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10)),
    stopLoading()
    // replace('/upload/review')
  ])(action$);

const getQuestionsDetailEpic = (action$) =>
  executeEpic(Types.GET_UPLOAD_QUESTIONDETAILLIST, getQuestions, (responseData) => [
    questionDetailList.set(responseData.response),
    stopLoading()
    // replace('/upload/review')
  ])(action$);

const approveQuestionEpic = (action$) =>
  executeEpic(Types.GET_UPLOAD_QUESTIONAPPROVE, getApproveQuestion, () => [
    stopLoading(),
    replace('/upload'),
    showAlert('Question Approved successfully', 'success')
  ])(action$);

const returnQuestionEpic = (action$) =>
  executeEpic(Types.POST_UPLOAD_QUESTIONRETURN, postRetunQuestion, () => [
    stopLoading(),
    questionFilter.set({ filters: '' }),
    replace('/upload'),
    showAlert('Question returned successfully', 'success')
  ])(action$);

const postQuestionImageEpic = (action$) =>
  executeEpic(Types.POST_UPLOAD_QUESTIONIMAGE, postQuestionImageApi, (response, payload) => [
    showAlert('Question image uploaded successfully', 'success'),
    question.get(payload?.questionId),
    stopLoading()
  ])(action$);

const postAnswerImageEpic = (action$) =>
  executeEpic(Types.POST_UPLOAD_ANSWERIMAGE, postAnswerImageApi, (response, payload) => [
    showAlert('Answer image uploaded successfully', 'success'),
    question.get(payload?.questionId),
    stopLoading()
  ])(action$);

const deleteQuestionImageEpic = (action$) =>
  executeEpic(Types.DELETE_UPLOAD_QUESTIONIMAGE, deleteQuestionImageApi, (response, payload) => [
    showAlert('Question image successfully remvoed', 'success'),
    question.get(payload?.questionId),
    stopLoading()
  ])(action$);

const deleteAnswerImageEpic = (action$) =>
  executeEpic(Types.DELETE_UPLOAD_ANSWERIMAGE, deleteAnswerImageApi, (response, payload) => [
    showAlert('Answer image successfully removed', 'success'),
    question.get(payload?.questionId),
    stopLoading()
  ])(action$);

const getTranslateQuestionEpic = (action$) =>
  executeEpic(Types.GET_UPLOAD_TRANSLATEQUESTION, getTranslateQuestionApi, (responseData) => [
    translateQuestion.set(responseData.response),
    stopLoading()
  ])(action$);

export default [
  uploadQuestionEpic,
  getQuestionEpic,
  getQuestionsEpic,
  getQuestionsDetailEpic,
  approveQuestionEpic,
  updateQuestionEpic,
  returnQuestionEpic,
  postQuestionImageEpic,
  postAnswerImageEpic,
  deleteQuestionImageEpic,
  deleteAnswerImageEpic,
  deleteQuestionEpic,
  getTranslateQuestionEpic
];
