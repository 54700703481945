import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profileInfo } from 'views/profile/redux/actions';
import { getUserInfo } from 'views/profile/redux/selectors';

export default function useStudentValidation() {
  const dispatch = useDispatch();
  const studentId = useSelector((state) => state?.auth?.data?.StudentId);
  const userType = useSelector((state) => state?.auth?.data?.UserType);
  const user = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(profileInfo.get());
  }, []);

  let isStudentValid = true;
  if (userType === 'Student') {
    if (!studentId || Number(studentId) < 1) {
      isStudentValid = false;
      return [isStudentValid];
    }
  }
  if (userType === 'Student' && !user?.isEmailVerified) {
    isStudentValid = user?.isEmailVerified;
    return [isStudentValid];
  }
  if (userType === 'Student' && !user?.isMobileVerified) {
    isStudentValid = user?.isMobileVerified;
    return [isStudentValid];
  }
  return [isStudentValid];
}
