import { createSelector } from 'reselect';

const getUserData = (state) => state.userData;
const getTokenAvailability = (state) => state.validateUserName;

const getUser = createSelector(getUserData, (userData) => userData?.user);
const getEmailAvailability = createSelector(
  getTokenAvailability,
  (availablility) => availablility?.isEmailTokenAvailable
);
const getPhoneAvailability = createSelector(
  getTokenAvailability,
  (availablility) => availablility?.isPhoneTokenAvailable
);
const getRegisterData = createSelector(getUserData, (userData) => userData?.registerData);
const getOtpData = createSelector(getUserData, (userData) => userData?.otpData);
const getActiveStep = createSelector(getUserData, (userData) => userData?.activeStep);
const getForgetPasswordInitiate = createSelector(
  getUserData,
  (userData) => userData?.forgetPasswordInitiate
);
const getFpOtpData = createSelector(getUserData, (userData) => userData?.fpOtpData);
const getTestOtpData = createSelector(getUserData, (userData) => userData?.testOtpData);

export {
  getUser,
  getEmailAvailability,
  getPhoneAvailability,
  getRegisterData,
  getOtpData,
  getActiveStep,
  getForgetPasswordInitiate,
  getFpOtpData,
  getTestOtpData
};
