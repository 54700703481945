import { combineEpics } from 'redux-observable';
import UserEpics from 'views/user/redux/epics';
import CommonEpics from 'views/common/redux/epics';
import ExamEpics from 'views/Exam/redux/epics';
// import UploadEpics from 'views/Upload/redux/epics';
import ProfileEpics from 'views/profile/redux/epics';
import CreateTestEpics from 'views/CreateTest/redux/epics';
import SubscriptionEpics from 'views/Subscription/redux/epics';
import HistoryEpics from 'views/History/redux/epics';
import DashboardEpics from 'views/Dashboard/redux/epics';
import CommonTestEpics from 'views/CommonTest/redux/epics';
import CourseEpics from 'views/Course/redux/epics';
import BulkImportEpics from 'views/BulkImport/redux/epics';
import QuestionEpics from 'views/Question/redux/epics';

export const rootEpic = combineEpics(
  ...UserEpics,
  ...CommonEpics,
  ...ExamEpics,
  // ...UploadEpics,
  ...ProfileEpics,
  ...CreateTestEpics,
  ...SubscriptionEpics,
  ...HistoryEpics,
  ...DashboardEpics,
  ...CommonTestEpics,
  ...CourseEpics,
  ...BulkImportEpics,
  ...QuestionEpics
);
