import produce from 'immer';
import * as Types from './types';

export const DEFAULT_STATE = {
  courseData: {
    course: {
      clientId: -1,
      commonTestLimit: 0,
      description: '',
      discPerc: 0,
      endDate: '',
      examId: -1,
      hasCommonTest: true,
      hasEbook: false,
      hasLimit: true,
      hasOnlineClass: false,
      hasPersonalTest: true,
      hasPracticeTest: true,
      isFree: false,
      isUnitLimit: false,
      maxSubscriber: 0,
      mrp: 0,
      name: '',
      price: 0,
      startDate: '',
      subscriberCount: 0,
      summary: ''
    },
    subjectLimits: [],
    unitLimits: [],
    unitSections: []
  },
  units: []
};

const createCourse = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_CLIENT_COURSE:
      state.client = action.payload;
      return state;
    case Types.SET_COURSEDATA_COURSE:
      state.courseData = action.payload;
      return state;
    case Types.SET_UNIT_COURSE:
      state.units = [...state?.units, ...action.payload];
      return state;
    default:
      return state;
  }
}, DEFAULT_STATE);

export { createCourse };
