import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh'
});

function NaviraLoading() {
  return (
    <Root>
      <CircularProgress />
    </Root>
  );
}

export default NaviraLoading;
