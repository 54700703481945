import { callApi } from '@helpers/utilities';

const caller = (method, url) => callApi({ method, url }).anonymous();

export const getStandardsApi = (standardParam) =>
  caller('GET', `/masters/classes?${standardParam}`);
export const getDepartmentsApi = () => caller('GET', `/masters/departments`);
export const getBoardsApi = () => caller('GET', `/enums/boards`);
export const getMediumApi = () => caller('GET', `/masters/mediums`);
export const getStateApi = () => caller('GET', `/staticMasters/states`);
export const getDistrictApi = (id) => caller('GET', `/staticMasters/states/${id}/districts`);
export const getZipCodeApi = (id) => caller('GET', `/staticMasters/districts/${id}/pincodes`);
export const getExamsApi = (clientParam) =>
  callApi({ method: 'GET', url: `/examsubscribe/clients/${clientParam}` }).withAuth();
export const getTestTypeApi = () => caller('GET', `/enums/testTypes`);
export const getSubjectApi = (subjectParam) =>
  caller('GET', `/masters/cdms?${subjectParam?.getBy}`);
export const getUnitApi = (unitParam) => caller('GET', `/masters/units?QueryType=3&${unitParam}`);
export const getTopicApi = (topicParam) =>
  caller('GET', `/masters/topics?QueryType=3&${topicParam}`);
export const getLevelApi = () => caller('GET', `/enums/questionLevels`);
export const getTestStatusApi = () => caller('GET', `/enums/testStatuses`);
export const getSchoolTypeApi = () => caller('GET', `/masters/schoolTypes`);
export const getExamListApi = () => caller('GET', `/masters/exam`);
export const getCourseListApi = (courseParam) =>
  caller('GET', `/dropdown/course?QueryType=3&${courseParam}`);
export const getClientListApi = (clientParam) =>
  caller('GET', `/dropdown/client?QueryType=3&${clientParam}`);
export const getCommonTestListApi = (testPararm) =>
  caller('GET', `/dropdown/commontest?QueryType=3&${testPararm}`);
export const getSubjectListApi = () => caller('GET', `/masters/subjects`);
export const getExamRuleApi = (examId) => caller('GET', `/masters/exam/${examId}`);
export const getPersonApi = (personParam) =>
  caller('GET', `/dropdown/userName?QueryType=3&${personParam}`);
export const getSubjectDetailApi = (subjectParam) =>
  caller(`GET`, `/masters/subjects/${subjectParam}`);
export const getQuestionCountApi = (examId) => caller(`GET`, `/dropdown/questionsplitup/${examId}`);
export const getQuestionTypeApi = () => caller('GET', `/enums/questionTypes`);
export const getQuestionPatternApi = () => caller('GET', `/enums/questionPatterns`);
export const getClptApi = (clptParam) => caller('GET', `/masters/clpt?${clptParam}`);
