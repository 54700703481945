import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_CREATETEST_${type?.toUpperCase()}_DATA`,
  (type) => `SET_CREATETEST_${type?.toUpperCase()}_DATA`,
  (type) => `POST_CREATETEST_${type?.toUpperCase()}_DATA`,
  (type) => `PUT_CREATETEST_${type?.toUpperCase()}_DATA`,
  (type) => `DELETE_CREATETEST_${type?.toUpperCase()}_DATA`
);

const commonTest = properties('commonTest');
const document = properties('document');
const question = properties('question');
const course = properties('course');
const subject = properties('subject');
const unit = properties('unit');
const documentCount = properties('documentCount');
const questionCount = properties('questionCount');
const documentFilter = properties('documentFilter');
const questionFilter = properties('questionFilter');
const selectedQuestions = properties('selectedQuestions');
const removeQuestions = properties('removeQuestions');
const clearQuestions = properties('clearQuestions');
const questionUpdate = properties('questionUpdate');
const selectedQuestion = properties('selectedQuestion');
const sectionUpdate = properties('sectionUpdate');
const questionList = properties('questionList');
const newExam = properties('newExam');
const testQuestion = properties('testQuestion');
const commonTestRemove = properties('commonTestRemove');
const commonTestFilter = properties('commonTestFilter');
const commonTestList = properties('commonTestList');
const commonTestActivate = properties('commonTestActivate');
const commonTestCount = properties('commonTestCount');
const testFilters = properties('testFilters');
const findQuestion = properties('findQuestion');

export {
  commonTest,
  document,
  course,
  question,
  subject,
  unit,
  documentCount,
  questionCount,
  documentFilter,
  questionFilter,
  selectedQuestions,
  removeQuestions,
  clearQuestions,
  questionUpdate,
  selectedQuestion,
  sectionUpdate,
  questionList,
  newExam,
  testQuestion,
  commonTestRemove,
  commonTestFilter,
  commonTestList,
  commonTestActivate,
  commonTestCount,
  testFilters,
  findQuestion
};
