import { callApi } from '@helpers/utilities';

export const getCourses = (studentId) =>
  callApi({
    method: 'GET',
    url: `/course/${studentId}/course`
  }).withAuth();

export const getNonSubscribedCoursesApi = (filteredParam) =>
  callApi({
    method: 'GET',
    url: `/course/subscribers?${filteredParam}`
  }).withAuth();

export const getSubscribedCoursesApi = (filteredParam) =>
  callApi({
    method: 'GET',
    url: `/course/subscribers?${filteredParam}`
  }).withAuth();

export const getAdminCourses = () =>
  callApi({
    method: 'GET',
    url: `/course`
  }).withAuth();

export const getCourseById = (id) =>
  callApi({
    method: 'GET',
    url: `/course/${id}`
  }).withAuth();

export const postPurchaseFreeCourse = ({ studentId, courseId }) =>
  callApi({
    method: 'POST',
    url: `/freeSubscription?studentId=${studentId}&courseId=${courseId}`
  }).withAuth();

export const postPurchaseNewCourse = ({ studentId, courseId }) =>
  callApi({
    method: 'POST',
    url: `/orders?studentId=${studentId}&courseId=${courseId}`
  }).withAuth();
export const postCapturePayment = ({ paymentId, body }) =>
  callApi({
    method: 'POST',
    url: `/payments/${paymentId}/capture`,
    body: { ...body }
  }).withAuth();
