import { memo } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  defalutBg: `${PREFIX}-defalutBg`,
  answeredBg: `${PREFIX}-answeredBg`,
  reviewBg: `${PREFIX}-reviewBg`,
  errorBg: `${PREFIX}-errorBg`,
  answeredMarkedBg: `${PREFIX}-answeredMarkedBg`
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: 24,
    minWidth: 24,
    borderRadius: '50%',
    margin: 8,
    fontSize: 14,
    width: '10%',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 12,
    color: 'black'
  },

  [`&.${classes.text}`]: {
    padding: '6px 8px'
  },

  [`& .${classes.defalutBg}`]: {
    backgroundColor: 'ivory'
  },

  [`& .${classes.answeredBg}`]: {
    backgroundColor: theme.palette.success.main
  },

  [`& .${classes.reviewBg}`]: {
    backgroundColor: theme.palette.primary.main
  },

  [`& .${classes.errorBg}`]: {
    backgroundColor: theme.palette.error.main
  },

  [`& .${classes.answeredMarkedBg}`]: {
    backgroundColor: theme.palette.warning.main
  }
}));

function QuestionPagination(props) {
  const {
    isAnswered,
    isCheckForReview,
    id,
    onSelected,
    isError,
    isSuccess,
    isPartB,
    isNotAttended
  } = props;
  const onQuestionSelected = (id) => {
    onSelected(id);
  };
  const proessBackground = () => {
    if (isCheckForReview) {
      if (isAnswered) return classes.answeredMarkedBg;
      return classes.reviewBg;
    }
    if (isAnswered) return classes.answeredBg;
    if (isSuccess) return classes.answeredBg;
    if (isNotAttended) return classes.reviewBg;
    if (isError) return classes.errorBg;
    if (isPartB) return classes.answeredBg;
  };
  return (
    <StyledButton
      className={clsx(
        classes.root,
        classes.text,
        // isCheckForReview
        //   ? classes.reviewBg
        //   : isAnswered
        //   ? classes.answeredBg
        //   : isSuccess
        //   ? classes.answeredBg
        //   : isError
        //   ? classes.errorBg
        //   : isNotAttended
        //   ? classes.reviewBg
        //   : classes.defalutBg
        proessBackground()
      )}
      onClick={() => onQuestionSelected(id)}
    >
      {id}
    </StyledButton>
  );
}

export default memo(QuestionPagination);
