import produce from 'immer';
import * as Types from './types';

const uploadUserData = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_BULKIMPORT_STUDENTUPLOAD:
      state.userData = action.payload;
      return state;
    case Types.SET_BULKIMPORT_STAFFUPLOAD:
      state.userData = action.payload;
      return state;
    case Types.SET_BULKIMPORT_CLIENT:
      state.client = action.payload;
      return state;
    default:
      return state;
  }
}, {});

export { uploadUserData };
