import { executeEpic } from '@helpers/utilities';
import * as CommonActions from 'app/redux/actions';
import * as Types from './types';
import {
  rankApi,
  scoreDetailApi,
  subscriptionDetailApi,
  lastTestDetailApi,
  testHisotryApi,
  studentStatusApi,
  staffTestHistoryApi,
  userStatusApi,
  studentStatusExcelApi,
  staffStatusExcelApi
} from '../api';
import {
  rank,
  scoreDetail,
  subscription,
  latestTest,
  testHistory,
  studentStatus,
  staffHistory,
  userStatus,
  navigationCount
} from './actions';

const rankEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_RANK, rankApi, (responseData) => [
    rank.set(responseData.response)
  ])(action$);

const scoreDetailEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_SCOREDETAIL, scoreDetailApi, (responseData) => [
    scoreDetail.set(responseData.response)
  ])(action$);

const subscriptionDetailEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_SCOREDETAIL, subscriptionDetailApi, (responseData) => [
    subscription.set(responseData.response)
  ])(action$);

const latestTestEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_LATESTTEST, lastTestDetailApi, (responseData) => [
    latestTest.set(responseData.response)
  ])(action$);

const testHistoryEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_TESTHISTORY, testHisotryApi, (responseData) => [
    testHistory.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const studentStatusEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_STUDENTSTATUS, studentStatusApi, (responseData) => [
    studentStatus.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const staffHistoryEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_STAFFHISTORY, staffTestHistoryApi, (responseData) => [
    staffHistory.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const userStatusEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_USERSTATUS, userStatusApi, (responseData) => [
    userStatus.set(responseData.response),
    navigationCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10)),
    CommonActions.stopLoading()
  ])(action$);

const studentStatusExcelEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_STUDENTSTATUSEXCEL, studentStatusExcelApi, (responseData) => {
    const blob = new Blob([responseData.response], {
      type: responseData.xhr.getResponseHeader('content-type')
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = responseData.xhr.getResponseHeader('content-disposition');
    let fileName = '';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      // if (fileNameMatch.length === 3) fileName = fileNameMatch[1];
      if (fileNameMatch.length === 3 && fileNameMatch != null && fileNameMatch[1]) {
        fileName = fileNameMatch[1].replace(/["]/g, '');
      }
    }
    if (fileName) {
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
    return [
      CommonActions.stopLoading(),
      CommonActions.showAlert('User data downloaded successfully', 'success')
    ];
  })(action$);

const staffStatusExcelEpic = (action$) =>
  executeEpic(Types.GET_DASHBOARD_STAFFSTATUSEXCEL, staffStatusExcelApi, (responseData) => {
    const blob = new Blob([responseData.response], {
      type: responseData.xhr.getResponseHeader('content-type')
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = responseData.xhr.getResponseHeader('content-disposition');
    let fileName = '';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      // if (fileNameMatch.length === 3) fileName = fileNameMatch[1];
      if (fileNameMatch.length === 3 && fileNameMatch != null && fileNameMatch[1]) {
        fileName = fileNameMatch[1].replace(/["]/g, '');
      }
    }
    if (fileName) {
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
    return [
      CommonActions.stopLoading(),
      CommonActions.showAlert('User data downloaded successfully', 'success')
    ];
  })(action$);

export default [
  rankEpic,
  scoreDetailEpic,
  subscriptionDetailEpic,
  latestTestEpic,
  testHistoryEpic,
  studentStatusEpic,
  staffHistoryEpic,
  userStatusEpic,
  studentStatusExcelEpic,
  staffStatusExcelEpic
];
