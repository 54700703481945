export const removeToken = () => localStorage.removeItem('__t');

export const saveToken = (token) => {
  removeToken();
  localStorage.setItem('__t', token);
};

export const saveToLocal = (key, value) => localStorage.setItem(key, value);

export const removeFromLocal = (key) => localStorage.removeItem(key);

export const getItemFromLocal = (key) => localStorage.getItem(key);

export const getToken = () => localStorage.getItem('__t');
