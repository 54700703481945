import { UserConfig } from 'views/user/userConfig';
import { LandingPageConfig } from 'views/Landing/landingPageConfig';
import { ProfileConfig } from 'views/profile/profileConfig';
import { ExamConfig } from 'views/Exam/examConfig';
// import { UploadConfig } from 'views/Upload/uploadConfig';
import { EventsConfig } from 'views/Events/eventsConfig';
import { CreateTestConfig } from 'views/CreateTest/createTestConfig';
import { SubscriptionConfig } from 'views/Subscription/subscriptionConfig';
import { HistoryConfig } from 'views/History/historyConfig';
import { DashboardConfig } from 'views/Dashboard/dashboardConfig';
import { CommonTestConfig } from 'views/CommonTest/commonTestConfig';
import { CourseConfig } from 'views/Course/courseConfig';
import { BulkImportConfig } from 'views/BulkImport/bulkImportConfig';
import { QuestionConfig } from 'views/Question/questionConfig';

const routes = [
  ...UserConfig.routes,
  ...LandingPageConfig.routes,
  ...ProfileConfig.routes,
  ...ExamConfig.routes,
  // ...UploadConfig.routes,
  ...EventsConfig.routes,
  ...CreateTestConfig.routes,
  ...SubscriptionConfig.routes,
  ...HistoryConfig.routes,
  ...DashboardConfig.routes,
  ...CommonTestConfig.routes,
  ...CourseConfig.routes,
  ...BulkImportConfig.routes,
  ...QuestionConfig.routes
];

// security code may write here//

export { routes };
