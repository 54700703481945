import { callApi } from '@helpers/utilities';

export const startPracticeTestApi = (testParam) =>
  callApi({
    method: 'POST',
    url: `/tests`,
    body: { ...testParam }
  }).withAuth();

export const saveTestApi = (saveTestParamObject) =>
  callApi({
    method: 'POST',
    url: `/tests/${saveTestParamObject?.testId}/save`,
    body: saveTestParamObject?.testRequest
  }).withAuth();

export const finishTestApi = (finishTestParam) =>
  callApi({
    method: 'GET',
    url: `/tests/${finishTestParam}/finish`
  }).withAuth();

// export const getCommonTestApi = (commonTestParam) =>
//   callApi({
//     method: "GET",
//     url: `/tests/common?${commonTestParam}`,
//   }).withAuth();

export const getCommonTestApi = ({ queryParam }) =>
  callApi({
    method: 'GET',
    url: `/tests/history?${queryParam}`
  }).withAuth();

export const resumeTestApi = (testParam) =>
  callApi({
    method: 'GET',
    url: `/tests/${testParam}/continue`
  }).withAuth();

export const pingTestApi = (testId) =>
  callApi({
    method: 'GET',
    url: `/tests/${testId}/ping`
  }).withAuth();
