import { PropMapper } from '@helpers/utilities';
import * as Types from './types';

const { properties } = PropMapper(
  Types,
  (type) => `GET_${type?.toUpperCase()}_COURSE`,
  (type) => `SET_${type?.toUpperCase()}_COURSE`,
  (type) => `POST_${type?.toUpperCase()}_COURSE`,
  (type) => `PUT_${type?.toUpperCase()}_COURSE`
);

const courseData = properties('courseData');
const client = properties('client');
const unit = properties('unit');
const assign = properties('assign');

export { client, courseData, unit, assign };
