import * as CommonActions from 'app/redux/actions';
import * as ViewCommonActions from 'views/common/redux/actions';
import { executeEpic } from '@helpers/utilities';
import { refreshToken } from 'views/common/redux/actions';
import { refreshToken as refreshTokenAction } from 'views/user/redux/actions';
import {
  getProfile,
  putUserInfo,
  postPersonalInfo,
  putPersonalInfo,
  postStudentInfo,
  putStudentInfo,
  postChangePassword,
  postContactVerifyApi,
  postVerifyCompleteApi,
  postRequestContactChangeApi,
  postConfirmContactChangeApi,
  postStaffInfoApi,
  putStaffInfoApi
} from '../api';
import {
  profileInfo,
  contactVerify,
  verifyComplete,
  requestContactChange,
  confirmContactChange
} from './actions';
import * as Types from './types';

const getProfileEpic = (action$) =>
  executeEpic(Types.GET_PROFILE_PROFILEINFO, getProfile, (responseData) => [
    profileInfo.set(responseData.response)
  ])(action$);

const putProfileUserEpic = (action$) =>
  executeEpic(Types.PUT_PROFILE_USERINFO, putUserInfo, () => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Personal profile saved', 'success'),
    profileInfo.get()
  ])(action$);

const postProfilePersonalEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_PERSONALINFO, postPersonalInfo, () => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Personal profile saved', 'success'),
    profileInfo.get()
  ])(action$);

const putProfilePersonalEpic = (action$) =>
  executeEpic(Types.PUT_PROFILE_PERSONALINFO, putPersonalInfo, () => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Personal profile saved', 'success')
  ])(action$);

const postProfileStudentEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_STUDENTINFO, postStudentInfo, ({ response }) => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Academic profile saved', 'success'),
    ViewCommonActions.authStudentId.set(response?.id),
    profileInfo.get(),
    refreshToken.get()
  ])(action$);

const putProfileStudentEpic = (action$) =>
  executeEpic(Types.PUT_PROFILE_STUDENTINFO, putStudentInfo, ({ response }) => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Academic profile saved', 'success'),
    ViewCommonActions.authStudentId.set(response?.id)
  ])(action$);

const postChangePasswordEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_CHANGEPASSWORD, postChangePassword, (responseData) => [
    CommonActions.showAlert('Your password has been updated successfully', 'success'),
    CommonActions.stopLoading(),
    refreshTokenAction.set(responseData?.response?.token),
    profileInfo.get()
  ])(action$);

const postContactVerifyEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_CONTACTVERIFY, postContactVerifyApi, (responseData) => [
    contactVerify.set(responseData.response),
    CommonActions.stopLoading(),
    confirmContactChange.set('')
  ])(action$);

const postVerifyCompleteEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_VERIFYCOMPLETE, postVerifyCompleteApi, (responseData) => [
    verifyComplete.set(responseData.response),
    profileInfo.get(),
    contactVerify.set({}),
    CommonActions.stopLoading()
  ])(action$);

const postRequestContactChangeEpic = (action$) =>
  executeEpic(
    Types.POST_PROFILE_REQUESTCONTACTCHANGE,
    postRequestContactChangeApi,
    (responseData) => [
      CommonActions.stopLoading(),
      requestContactChange.set(responseData.response),
      confirmContactChange.set('')
    ]
  )(action$);

const postConfirmContactChangeEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_CONFIRMCONTACTCHANGE, postConfirmContactChangeApi, () => [
    profileInfo.get(),
    confirmContactChange.set(true),
    CommonActions.stopLoading()
  ])(action$);

const postProfileStaffEpic = (action$) =>
  executeEpic(Types.POST_PROFILE_STAFFINFO, postStaffInfoApi, ({ response }) => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Staff profile saved', 'success'),
    ViewCommonActions.authStaffId.set(response?.id),
    profileInfo.get(),
    refreshToken.get()
  ])(action$);

const putProfileStaffEpic = (action$) =>
  executeEpic(Types.PUT_PROFILE_STAFFINFO, putStaffInfoApi, ({ response }) => [
    CommonActions.stopLoading(),
    CommonActions.showAlert('Staff profile saved', 'success'),
    ViewCommonActions.authStaffId.set(response?.id)
  ])(action$);

export default [
  getProfileEpic,
  putProfileUserEpic,
  postProfilePersonalEpic,
  putProfilePersonalEpic,
  postProfileStudentEpic,
  putProfileStudentEpic,
  postChangePasswordEpic,
  postContactVerifyEpic,
  postVerifyCompleteEpic,
  postRequestContactChangeEpic,
  postConfirmContactChangeEpic,
  postProfileStaffEpic,
  putProfileStaffEpic
];
