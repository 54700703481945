export const SET_PROFILE_PROFILEINFO = 'SET_PROFILE_PROFILEINFO';
export const GET_PROFILE_PROFILEINFO = 'GET_PROFILE_PROFILEINFO';

export const SET_PROFILE_USERINFO = 'SET_PROFILE_USERINFO';
export const POST_PROFILE_USERINFO = 'POST_PROFILE_USERINFO';
export const PUT_PROFILE_USERINFO = 'PUT_PROFILE_USERINFO';

export const SET_PROFILE_STUDENTINFO = 'SET_PROFILE_STUDENTINFO';
export const POST_PROFILE_STUDENTINFO = 'POST_PROFILE_STUDENTINFO';
export const PUT_PROFILE_STUDENTINFO = 'PUT_PROFILE_STUDENTINFO';

export const POST_PROFILE_PERSONALINFO = 'POST_PROFILE_PERSONALINFO';
export const PUT_PROFILE_PERSONALINFO = 'PUT_PROFILE_PERSONALINFO';
export const SET_PROFILE_PERSONALINFO = 'SET_PROFILE_PERSONALINFO';

export const POST_PROFILE_CHANGEPASSWORD = 'POST_PROFILE_CHANGEPASSWORD';

export const POST_PROFILE_CONTACTVERIFY = 'POST_PROFILE_CONTACTVERIFY';
export const SET_PROFILE_CONTACTVERIFY = 'SET_PROFILE_CONTACTVERIFY';

export const POST_PROFILE_VERIFYCOMPLETE = 'POST_PROFILE_VERIFYCOMPLETE';
export const SET_PROFILE_VERIFYCOMPLETE = 'SET_PROFILE_VERIFYCOMPLETE';

export const POST_PROFILE_REQUESTCONTACTCHANGE = 'POST_PROFILE_REQUESTCONTACTCHANGE';
export const SET_PROFILE_REQUESTCONTACTCHANGE = 'SET_PROFILE_REQUESTCONTACTCHANGE';

export const POST_PROFILE_CONFIRMCONTACTCHANGE = 'POST_PROFILE_CONFIRMCONTACTCHANGE';
export const SET_PROFILE_CONFIRMCONTACTCHANGE = 'SET_PROFILE_CONFIRMCONTACTCHANGE';

export const SET_PROFILE_STAFFINFO = 'SET_PROFILE_STAFFINFO';
export const POST_PROFILE_STAFFINFO = 'POST_PROFILE_STAFFINFO';
export const PUT_PROFILE_STAFFINFO = 'PUT_PROFILE_STAFFINFO';
