import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as StorageHelper from '@helpers/utilities';
import jwtDecode from 'jwt-decode';
import * as Actions from 'views/common/redux/actions';

const storedToken = StorageHelper.getToken();
const setToken = (dispatch) => (token) => dispatch(Actions.auth.set(jwtDecode(token)));

function Auth(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.userData?.user);

  useEffect(() => {
    if (user?.token) {
      StorageHelper.saveToken(user.token);
      setToken(dispatch)(user?.token);
    } else if (storedToken) {
      setToken(dispatch)(storedToken);
    }
  }, [user]);

  //! IMPORTANT - Applicaiton should sign-out if token is undefined

  return <>{props.children}</>;
}

export default memo(Auth);
