import { lazy } from 'react';

export const CommonTestConfig = {
  routes: [
    {
      path: '/commonTest',
      exact: true,
      component: lazy(() => import('./index'))
    }
  ]
};
