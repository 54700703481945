import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TimerIcon from '@mui/icons-material/Timer';

const PREFIX = 'timer';

const classes = {
  timierIcon: `${PREFIX}-timierIcon`,
  timierText: `${PREFIX}-timierText`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.timierIcon}`]: {
    margin: 3
  },

  [`& .${classes.timierText}`]: {
    margin: '15px 15px 15px 15px'
  }
}));

function Timer(props) {
  const convertNumber = (value) => String(value).padStart(2, '0');
  const convertToSeconds = (secs) => {
    const hours = Math.floor(secs / (60 * 60));
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);
    return `${convertNumber(hours)}:${convertNumber(minutes)}:${convertNumber(seconds)}`;
  };

  if (props.timerSeconds) {
    return (
      <Root>
        <Grid container>
          <Grid item xs={1} sm={1}>
            <TimerIcon color="primary" className={classes.timierIcon} />
          </Grid>
          <Grid item xs={8} sm={8}>
            <Typography
              component="span"
              color="primary"
              variant="subtitle1"
              className={classes.timierText}
            >
              {`${convertNumber(props.timerSeconds.hours)}:${convertNumber(
                props.timerSeconds.minutes
              )}:${convertNumber(props.timerSeconds.seconds)}/${convertToSeconds(
                props.totalDuration
              )}`}
            </Typography>
          </Grid>
        </Grid>
      </Root>
    );
  }
}

export default memo(Timer);
