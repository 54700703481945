import { replace } from 'connected-react-router';
import * as CommonActions from 'app/redux/actions';
import { executeEpic } from '@helpers/utilities';
import * as Types from './types';
import { practice, finish, common, answerSelected } from './actions';
import {
  startPracticeTestApi,
  saveTestApi,
  finishTestApi,
  getCommonTestApi,
  resumeTestApi,
  pingTestApi
} from '../api';

const startPracticeTestEpic = (action$) =>
  executeEpic(Types.POST_PRACTICE_TEST, startPracticeTestApi, (responseData) => [
    practice.set(responseData.response),
    CommonActions.stopLoading(),
    replace('/exam')
  ])(action$);

const resumeTestEpic = (action$) =>
  executeEpic(Types.GET_RESUME_TEST, resumeTestApi, (responseData) => [
    practice.set(responseData.response),
    CommonActions.stopLoading(),
    replace('/exam')
  ])(action$);

const saveTestEpic = (action$) =>
  executeEpic(
    Types.POST_SAVE_TEST,
    saveTestApi,
    () => [
      // practice.set(responseData.response),
      // CommonActions.stopLoading(),
    ],
    (errorResponse, actionPayload) => {
      const {
        testId,
        questionIndex,
        questionId,
        answeredOptions: options
      } = actionPayload?.testRequest?.[0];
      return [
        answerSelected.set({ testId, questionIndex, questionId, answeredOptions: options[0] })
      ];
    }
  )(action$);

const finishTestEpic = (action$) =>
  executeEpic(Types.GET_FINISH_TEST, finishTestApi, (responseData) => [
    finish.set(responseData.response),
    replace('/examResult'),
    CommonActions.stopLoading()
  ])(action$);

const commonTestEpic = (action$) =>
  executeEpic(Types.GET_COMMON_TEST, getCommonTestApi, (responseData) => [
    common.set(responseData.response),
    CommonActions.stopLoading()
  ])(action$);

const pingTestEpic = (action$) => executeEpic(Types.GET_PING_TEST, pingTestApi, () => [])(action$);

export default [
  startPracticeTestEpic,
  saveTestEpic,
  finishTestEpic,
  commonTestEpic,
  resumeTestEpic,
  pingTestEpic
];
