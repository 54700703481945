import { lazy } from 'react';

export const ProfileConfig = {
  routes: [
    {
      path: '/profile',
      exact: true,
      component: lazy(() => import('./index'))
    }
  ]
};
