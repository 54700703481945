import * as Types from './types';

// Error Data process
const errorResponse = (error) => ({
  type: Types.ERROR_RESPONSE,
  payload: error
});

const startLoading = () => ({
  type: Types.LOADING,
  payload: true
});
const stopLoading = () => ({
  type: Types.LOADING,
  payload: false
});
const setTheme = (isDarkTheme) => ({
  type: Types.SET_THEME,
  payload: isDarkTheme
});
const getDefaultThemeAction = () => ({
  type: Types.DEFAULT_THEME
});
const getDarkThemeAction = () => ({
  type: Types.DARK_THEME
});

const showAlert = (data, alertType) => ({
  type: Types.SHOW_N_HIDE_ALERT,
  payload: { data, type: alertType, show: true }
});

const hideAlert = () => ({
  type: Types.SHOW_N_HIDE_ALERT,
  payload: { show: false }
});

const showDialog = (payload) => ({
  type: Types.SHOW_CUSTOM_DIALOG,
  payload
});

const hideDialog = (payload) => ({
  type: Types.HIDE_CUSTOM_DIALOG,
  payload
});

const setIsUserAuthorized = (payload) => ({
  type: Types.IS_USER_AUTHORIZED,
  payload
});

const setIsSessionIdle = (payload) => ({
  type: Types.IS_SESSION_IDLE,
  payload
});

export {
  errorResponse,
  startLoading,
  stopLoading,
  setTheme,
  getDefaultThemeAction,
  getDarkThemeAction,
  showAlert,
  hideAlert,
  showDialog,
  hideDialog,
  setIsUserAuthorized,
  setIsSessionIdle
};
