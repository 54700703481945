import { callApi } from '@helpers/utilities';

export const getClientApi = () =>
  callApi({
    method: 'GET',
    url: `/clients?QueryType=3`
  }).withAuth();

export const getSubjectApi = (subjectParam) =>
  callApi({
    method: 'GET',
    url: `/masters/cdms?${subjectParam?.param}`
  }).anonymous();

export const getUnitApi = (unitParam) =>
  callApi({
    method: 'GET',
    url: `/masters/units?${unitParam}`
  }).anonymous();

export const postCourseApi = (courseParam) =>
  callApi({
    method: 'POST',
    url: `/course`,
    body: { ...courseParam }
  }).withAuth();

export const postAssignCourseApi = (courseParam) =>
  callApi({
    method: 'POST',
    url: `/subscribe`,
    body: { ...courseParam }
  }).withAuth();
