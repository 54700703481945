import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// core components
import { QuestionPagination } from 'components';
// Store action
import * as ExamActions from 'views/Exam/redux/actions';
import * as HistoryActions from 'views/History/redux/actions';
import * as CreateTestActions from 'views/CreateTest/redux/actions';

const PREFIX = 'index';

const classes = {
  layoutStyle: `${PREFIX}-layoutStyle`,
  legendStyle: `${PREFIX}-legendStyle`,
  answeredBg: `${PREFIX}-answeredBg`,
  reviewBg: `${PREFIX}-reviewBg`,
  answeredMarkedBg: `${PREFIX}-answeredMarkedBg`,
  errorBg: `${PREFIX}-errorBg`,
  notAttendedBg: `${PREFIX}-notAttendedBg`,
  noteStyle: `${PREFIX}-noteStyle`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.layoutStyle}`]: {
    background: theme.palette.mode === 'light' ? '#e3e1e2' : theme.palette.background.paper,
    margin: 0,
    boxShadow: '-6px 3px 7px #1917182b',
    padding: '20px 10px'
  },

  [`& .${classes.legendStyle}`]: {
    height: 18,
    minWidth: 18,
    borderRadius: '50%'
  },

  [`& .${classes.answeredBg}`]: {
    backgroundColor: theme.palette.success.main
  },

  [`& .${classes.reviewBg}`]: {
    backgroundColor: theme.palette.primary.main
  },

  [`& .${classes.answeredMarkedBg}`]: {
    backgroundColor: theme.palette.warning.main
  },

  [`& .${classes.errorBg}`]: {
    backgroundColor: theme.palette.error.main
  },

  [`& .${classes.notAttendedBg}`]: {
    backgroundColor: theme.palette.primary.main
  },

  [`& .${classes.noteStyle}`]: {
    fontWeight: 600
  }
}));

// sx={{
//   background: (theme) =>
//     theme.palette.mode === 'light' ? '#e3e1e2' : theme.palette.background.paper,
//   margin: 0,
//   boxShadow: '-6px 3px 7px #1917182b',
//   padding: '20px 10px'
// }}

export default function QuestionOverview(props) {
  // const theme = useTheme();
  const { questions, flow, subject, commonTestSections } = props;
  const [totalPartB, setTotalPartB] = useState(0);
  // Store Dispatch
  const dispatch = useDispatch();
  const onSelectQuestion = (id) => {
    if (flow === 'createTest') dispatch(CreateTestActions.selectedQuestion.set(id - 1));
    else if (flow === 'exam') dispatch(ExamActions.selectedQuestion.set(id - 1));
    else if (flow === 'history') dispatch(HistoryActions.currentQuestion.set(id - 1));
  };

  useEffect(() => {
    if (questions?.length > 0) {
      setTotalPartB(
        questions?.filter((question) => question.subjectId === subject && question.sectionNo === 2)
          .length
      );
    }
  }, [questions]);

  const calculateMaxQuestions = (section) =>
    commonTestSections?.filter(
      (testSection) => testSection?.subjectId === subject && testSection?.sectionNo === section
    )[0]?.maxAnswerableQuestion;

  const calculateCorrectAnswer = (question) => {
    if (question?.questionPattern === 6) {
      return (
        question?.correctOptions?.length === question?.answeredOptions?.length &&
        question?.correctOptions.every((element) => question?.answeredOptions.includes(element))
      );
    }
    if (question?.questionPattern === 2) {
      return true;
    }
    return question?.correctOptions.includes(question?.answeredOptions[0]);
  };

  const findAnsweredQuestion = (question) => {
    if (question?.questionPattern === 2) {
      return question?.answerValue;
    }
    return question?.answeredOptions?.length > 0;
  };

  if (flow === 'createTest') {
    return (
      <StyledGrid
        container
        sx={{
          background: (theme) =>
            theme.palette.mode === 'light' ? '#e3e1e2' : theme.palette.background.paper,
          margin: 0,
          boxShadow: '-6px 3px 7px #1917182b',
          padding: '20px 10px'
        }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            Question Overview
          </Typography>
        </Grid>
        {questions?.filter((question) => question?.subjectId === subject).length > 0 &&
          questions
            .filter((question) => question?.subjectId === subject)
            .map((question, index) => (
              <QuestionPagination
                key={index}
                id={question?.questionKey}
                isPartB={question?.sectionNo === 2}
                onSelected={onSelectQuestion}
              />
            ))}
        <Grid item container xs={12} sm={12} md={12} lg={12} alignItems="center">
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Button className={clsx(classes.legendStyle, classes.answeredBg)}>{totalPartB}</Button>
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant="body1">Part B Question</Typography>
          </Grid>
        </Grid>
      </StyledGrid>
    );
  }

  if (flow === 'history') {
    return (
      <StyledGrid
        container
        sx={{
          background: (theme) =>
            theme.palette.mode === 'light' ? '#e3e1e2' : theme.palette.background.paper,
          margin: 0,
          boxShadow: '-6px 3px 7px #1917182b',
          padding: '20px 10px'
        }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            Question Overview
          </Typography>
        </Grid>
        {questions?.filter(
          (question) => question?.subjectId === subject && question.sectionNo === 1
        ).length > 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              Section A
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {questions?.filter((question) => question?.subjectId === subject).length > 0 &&
            questions
              ?.filter((question) => question?.subjectId === subject && question?.sectionNo === 1)
              .map((question, index) => (
                <QuestionPagination
                  key={index}
                  isAnswered={calculateCorrectAnswer(question)}
                  isError={!calculateCorrectAnswer(question)}
                  isNotAttended={question?.answeredOptions?.length === 0}
                  id={question?.orderNo}
                  onSelected={onSelectQuestion}
                />
              ))}
        </Grid>
        {questions?.filter((question) => question.sectionNo === 2 && question.subjectId === subject)
          .length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Section B
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {questions
                ?.filter((question) => question?.subjectId === subject && question.sectionNo === 2)
                .map((question, index) => (
                  <QuestionPagination
                    key={index}
                    isAnswered={calculateCorrectAnswer(question)}
                    isError={!calculateCorrectAnswer(question)}
                    isNotAttended={question?.answeredOptions?.length === 0}
                    id={question?.orderNo}
                    onSelected={onSelectQuestion}
                  />
                ))}
            </Grid>
          </>
        )}
        {questions?.filter((question) => question.sectionNo === 3 && question.subjectId === subject)
          .length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Section B
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {questions
                ?.filter((question) => question?.subjectId === subject && question.sectionNo === 3)
                .map((question, index) => (
                  <QuestionPagination
                    key={index}
                    iisAnswered={calculateCorrectAnswer(question)}
                    isError={!calculateCorrectAnswer(question)}
                    isNotAttended={question?.answeredOptions?.length === 0}
                    id={question?.orderNo}
                    onSelected={onSelectQuestion}
                  />
                ))}
            </Grid>
          </>
        )}
        {questions?.filter((question) => question.sectionNo === 0 && question.subjectId === subject)
          .length > 0 && (
          <Grid item xs={12}>
            {questions
              ?.filter((question) => question?.subjectId === subject && question?.sectionNo === 0)
              .map((question, index) => (
                <QuestionPagination
                  key={index}
                  isAnswered={calculateCorrectAnswer(question)}
                  isError={!calculateCorrectAnswer(question)}
                  isNotAttended={question?.answeredOptions?.length === 0}
                  id={question?.orderNo}
                  onSelected={onSelectQuestion}
                />
              ))}
          </Grid>
        )}
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Button className={clsx(classes.legendStyle, classes.answeredBg)} /> Correct
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Button className={clsx(classes.legendStyle, classes.errorBg)} /> Wrong
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Button className={clsx(classes.legendStyle, classes.notAttendedBg)} /> Not Attended
        </Grid>
      </StyledGrid>
    );
  }

  return (
    <StyledGrid
      container
      sx={{
        background: (theme) =>
          theme.palette.mode === 'light' ? '#e3e1e2' : theme.palette.background.paper,
        margin: 0,
        boxShadow: '-6px 3px 7px #1917182b',
        padding: '20px 10px'
      }}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center">
          Question Overview
        </Typography>
      </Grid>
      {questions?.filter((question) => question?.sectionNo === 1 && question.subjectId === subject)
        .length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              Section A
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {questions
              ?.filter((question) => question?.subjectId === subject && question?.sectionNo === 1)
              .map((question, index) => (
                <QuestionPagination
                  key={index}
                  isAnswered={findAnsweredQuestion(question)}
                  isCheckForReview={question.isCheckForReview}
                  id={question?.orderNo}
                  onSelected={onSelectQuestion}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.noteStyle} variant="caption">
              {`Note : Section A maximum answerable questions are ${calculateMaxQuestions(1)}`}
            </Typography>
          </Grid>
        </>
      )}
      {questions?.filter((question) => question.sectionNo === 2 && question.subjectId === subject)
        .length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              Section B
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {questions
              ?.filter((question) => question?.subjectId === subject && question?.sectionNo === 2)
              .map((question, index) => (
                <QuestionPagination
                  key={index}
                  isAnswered={findAnsweredQuestion(question)}
                  isCheckForReview={question.isCheckForReview}
                  id={question?.orderNo}
                  onSelected={onSelectQuestion}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.noteStyle} variant="caption">
              {`Note : Section B maximum answerable questions are ${calculateMaxQuestions(2)}`}
            </Typography>
          </Grid>
        </>
      )}
      {questions?.filter((question) => question?.sectionNo === 3 && question.subjectId === subject)
        .length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              Section A
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {questions
              ?.filter((question) => question?.subjectId === subject && question?.sectionNo === 3)
              .map((question, index) => (
                <QuestionPagination
                  key={index}
                  isAnswered={findAnsweredQuestion(question)}
                  isCheckForReview={question.isCheckForReview}
                  id={question?.orderNo}
                  onSelected={onSelectQuestion}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.noteStyle} variant="caption">
              {`Note : Section C maximum answerable questions are ${calculateMaxQuestions(3)}`}
            </Typography>
          </Grid>
        </>
      )}
      {questions?.filter((question) => question?.sectionNo === 0 && question.subjectId === subject)
        .length > 0 && (
        <Grid item xs={12}>
          {questions
            ?.filter((question) => question?.subjectId === subject && question?.sectionNo === 0)
            .map((question, index) => (
              <QuestionPagination
                key={index}
                isAnswered={findAnsweredQuestion(question)}
                isCheckForReview={question.isCheckForReview}
                id={question?.orderNo}
                onSelected={onSelectQuestion}
              />
            ))}
        </Grid>
      )}
      <Grid item container xs={6} sm={6} md={6} lg={6} alignItems="center">
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Button className={clsx(classes.legendStyle, classes.answeredBg)} />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography variant="body1">Answered</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={6} sm={6} md={6} lg={6} alignItems="center">
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Button className={clsx(classes.legendStyle, classes.reviewBg)} />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography variant="body1">Marked for Review</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={6} sm={6} md={6} lg={6} alignItems="center">
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Button className={clsx(classes.legendStyle, classes.answeredMarkedBg)} />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography variant="body1">Answered and Marked for Review</Typography>
        </Grid>
      </Grid>
    </StyledGrid>
  );
}
