import { lazy } from 'react';

export const DashboardConfig = {
  routes: [
    {
      path: '/dashboard',
      exact: true,
      component: lazy(() => import('./index'))
    },
    {
      path: '/userStatus',
      exact: true,
      component: lazy(() => import('./sections/usersStatusTab'))
    }
  ]
};
