import { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import KatexComponent from '../KatexComponent';

// const answerDescription = {
//   '& span': {
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
//     fontWeight: '400 !important',
//     fontSize: '0.95rem !important',
//     lineHeight: '1.57 !important',
//     letterSpacing: '0.00714em !important',
//     color: 'text.primary'
//   },
//   p: {
//     marginBottom: '0px auto !important',
//     display: 'flex',
//     alignItems: 'center'
//   }
// };

const backgroundColor = {
  color: 'info.contrastText',
  bgColor: 'info.main'
};

const size = {
  width: '36px',
  height: '36px',
  margin: '8px',
  fontSize: '14px',
  minWidth: '24px',
  textAlign: 'center',
  fontWeight: 'bold',
  borderRadius: '50%'
};

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  '& > *': {
    margin: 0.5
  }
});

const optionLetterCreation = (optionKey) => {
  switch (optionKey) {
    case 0:
      return 'A';
    case 1:
      return 'B';
    case 2:
      return 'C';
    case 3:
      return 'D';
    case 4:
      return 'E';
    default:
      return 'Z';
  }
};

const Image = styled('img')({
  width: '100%',
  height: '100%'
});

const ImageLayout = (props) =>
  props?.images?.map((image, index) => (
    <Stack key={index} spacing={1} sx={{ marginLeft: '24px', mt: '24px' }}>
      <Image src={image?.fullPath} alt={image.name} />
    </Stack>
  ));

function AnswerSections(props) {
  const { uniqueKey, answer, isSelected, handleClick } = props;
  const handleClickEvent = () => {
    if (handleClick) handleClick(answer?.id);
    console.log(`event triggered${answer?.id}`);
  };

  return (
    <Root>
      <Typography onClick={handleClickEvent}>
        {isSelected ? (
          <Button
            variant="contained"
            disableRipple
            size="small"
            sx={{ ...backgroundColor, ...size }}
          >
            {optionLetterCreation(uniqueKey)}
          </Button>
        ) : (
          <Button variant="outlined" disableRipple size="small" sx={{ ...size }}>
            {optionLetterCreation(uniqueKey)}
          </Button>
        )}
      </Typography>

      {answer?.images && <ImageLayout images={answer?.images} />}
      {answer?.name && <KatexComponent text={answer?.name} />}
      {/* {isHtml ? (
        <Typography
          display="inline"
          sx={{ ...answerDescription }}
          dangerouslySetInnerHTML={createMarkup(optionDescription)}
          onClick={handleClickEvent}
        />
      ) : (
        <Typography
          display="inline"
          sx={{ ...answerDescription, padding: '14px' }}
          onClick={handleClickEvent}
        >
          {optionDescription}
        </Typography>
      )} */}
    </Root>
  );
}

export default memo(AnswerSections);
AnswerSections.propTypes = {
  uniqueKey: PropTypes.number,
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func
};
