import { stringify } from 'query-string';

export const mapAction = (type, payload) => ({ type, payload });

export const PropMapper = (types, getterType, setterType, postType, putType, deleteType) => ({
  properties: (type) => ({
    get: (payload) => mapAction(types[getterType(type)], payload),
    set: (payload) => mapAction(types[setterType(type)], payload),
    post: (payload) => mapAction(types[postType(type)], payload),
    put: (payload) => mapAction(types[putType(type)], payload),
    delete: (payload) => mapAction(types[deleteType(type)], payload)
  })
});

export const GetType = (types, template, postTemplate, putTemplate) => ({
  mapType: (type) => types[template(type)],
  postType: (type) => types[postTemplate(type)],
  putType: (type) => types[putTemplate(type)]
});

export const generateFilterParam = (filterObject) => {
  if (filterObject) {
    const queryString = Object.keys(filterObject)
      .map((key) => {
        if (
          filterObject[key] !== undefined &&
          filterObject[key] !== null &&
          filterObject[key] !== -1
        )
          return `${key}==${filterObject[key]}`;
      })
      .join(',');
    if (queryString) return stringify({ filters: queryString }, { skipEmptyString: true });
    return '';
  }
};

export const generateFilterObject = (filterData) => {
  const filterObject = { ...filterData };
  Object.entries(filterObject).forEach(([key, val]) => {
    if (val === '' || val === -1) delete filterObject[key];
  });
  if (filterObject) {
    const queryString = Object.keys(filterObject)
      .map((key) => {
        if (
          filterObject[key] !== undefined &&
          filterObject[key] !== null &&
          filterObject[key] !== -1 &&
          filterObject[key] !== '' &&
          filterObject[key] !== 0
        ) {
          if (Number.isInteger(Number(filterObject[key])) || typeof filterObject[key] === 'boolean')
            return `${key}==${filterObject[key]}`;
          if (typeof filterObject[key] === 'string') return `${key}@=${filterObject[key]}`;
        }
      })
      .join(',');
    if (queryString) return { filters: queryString };
    return {};
  }
};

export const generatePieChartData = (objectData) => {
  let chartObject = {};
  const chartData = [];
  const chartPattern = [];
  objectData.map((data, index) => {
    const localObject = {};
    const patternObject = {};
    localObject.id = data.name;
    localObject.label = data.name;
    localObject.value = data.value;
    patternObject.match = { id: data.name };
    if (index % 2 === 0) patternObject.id = 'dots';
    else patternObject.id = 'lines';
    chartData.push({ ...localObject });
    chartPattern.push({ ...patternObject });
  });
  chartObject = { chartData, chartPattern };
  return chartObject;
};

export const generateNewPie = (objectData) => {
  const chartValue = [];
  const chartLabel = [];
  objectData?.map((data) => {
    chartValue.push(data?.value);
    chartLabel.push(data?.name);
  });
  return { chartLabel, chartValue };
};
