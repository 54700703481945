import { memo } from 'react';
import { Chip, Grid } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

function UploadSection(props) {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <label htmlFor={`${props.name}image`}>
          <input
            style={{ display: 'none' }}
            id={`${props.name}image`}
            name={props.name}
            type="file"
            onChange={props.onUpload}
            multiple
          />
          <Chip
            sx={{ cursor: 'pointer' }}
            color="primary"
            icon={<PhotoCamera />}
            label={`Add ${props.name[0].toUpperCase() + props.name.slice(1)} Image`}
          />
        </label>
      </Grid>
    </Grid>
  );
}

export default memo(UploadSection);
