import { executeEpic } from '@helpers/utilities';
import { stopLoading, showAlert } from 'app/redux/actions';
import { replace } from 'connected-react-router';
import * as Types from './types';
import {
  getCommonTestApi,
  putCommonTestApi,
  getCommonTestDetailApi,
  getCommonTestCourseApi,
  getCommonTestStudentApi,
  postReassignTestApi,
  getCommonTestActivateApi
} from '../api';
import { testInfo, course, student, studentCount, navigationFilter } from './actions';

const getCommonTestEpic = (action$) =>
  executeEpic(Types.GET_TESTINFO_COMMON, getCommonTestApi, (responseData) => [
    stopLoading(),
    testInfo.set(responseData.response)
  ])(action$);

const putCommonTestEpic = (action$) =>
  executeEpic(Types.PUT_TESTINFO_COMMON, putCommonTestApi, () => [
    stopLoading(),
    testInfo.get('filters=&page=1&pageSize=20&sorts=-createdAt')
  ])(action$);

const getCommonTestDetailEpic = (action$) =>
  executeEpic(Types.GET_TESTDETAILINFO_COMMON, getCommonTestDetailApi, () => [
    stopLoading()
    // testInfo.set(responseData?.response?.test?.isPublished),
  ])(action$);

const getCommonTestCourseEpic = (action$) =>
  executeEpic(Types.GET_COURSE_COMMON, getCommonTestCourseApi, (responseData) => [
    stopLoading(),
    course.set(responseData.response)
  ])(action$);

const getCommonTestStudentEpic = (action$) =>
  executeEpic(Types.GET_STUDENT_COMMON, getCommonTestStudentApi, (responseData) => [
    stopLoading(),
    student.set(responseData.response),
    studentCount.set(parseInt(responseData.xhr.getResponseHeader('X-Total-Count'), 10))
  ])(action$);

const postReassignTestEpic = (action$) =>
  executeEpic(Types.POST_REASSIGN_COMMON, postReassignTestApi, () => [
    stopLoading(),
    replace('/dashboard'),
    testInfo.set([]),
    course.set([]),
    navigationFilter.set({ filters: '' }),
    student.set([]),
    showAlert('Reassign Common test has been done successfully', 'success')
  ])(action$);

const getActivateEpic = (action$) =>
  executeEpic(Types.GET_ACTIVATE_COMMON, getCommonTestActivateApi, () => [
    stopLoading(),
    testInfo.get('filters=&page=1&pageSize=20&sorts=-createdAt'),
    showAlert('Common test has been activated successfully', 'success')
  ])(action$);

export default [
  getCommonTestEpic,
  putCommonTestEpic,
  getCommonTestDetailEpic,
  getCommonTestCourseEpic,
  getCommonTestStudentEpic,
  postReassignTestEpic,
  getActivateEpic
];
