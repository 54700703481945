// @mui/icons-material
import Upload from 'assets/img/module/upload.png';
import Dashboard from 'assets/img/module/dashboard.png';
import Exam from 'assets/img/module/exam.png';
import History from 'assets/img/module/history.png';
import Course from 'assets/img/module/course.png';
import User from 'assets/img/module/user.png';

const superAdminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard
  },
  {
    path: '/upload',
    name: 'Upload',
    icon: Upload
  },
  {
    path: '/createTest',
    name: 'Create Test',
    icon: Exam
  },
  {
    path: '/history',
    name: 'History',
    icon: History
  },
  {
    path: '/course',
    name: 'Courses',
    icon: Course
  },
  {
    path: '/user',
    name: 'User',
    icon: User
  }
];

export default superAdminRoutes;
