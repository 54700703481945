import { callApi } from '@helpers/utilities';

const caller = (method, url) => callApi({ method, url }).anonymous();

export const getClientApi = (clientUrlId) => caller('GET', `/clients/url/${clientUrlId}`);

export const avalabilityPhoneApi = (userNameQueryParam) =>
  caller('GET', `/mobileAvailable?${userNameQueryParam}`);

export const avalabilityEmailApi = (userNameQueryParam) =>
  caller('GET', `/emailAvaliable?${userNameQueryParam}`);

export const registerUserApi = (userData) =>
  callApi({
    method: 'POST',
    url: `/student/register`,
    body: { ...userData?.signUpParam }
  }).anonymous();

export const validateOtpApi = (otpQueryParam) => caller('GET', `/verifyOTP?${otpQueryParam}`);

export const registerCompleteApi = (userData) =>
  callApi({
    method: 'POST',
    url: `/student/register/completed`,
    body: { ...userData }
  }).anonymous();

export const loginApi = (userParams) =>
  callApi({
    method: 'POST',
    url: `/users/login`,
    body: { ...userParams }
  }).anonymous();

export const logoutApi = () => callApi({ method: 'GET', url: `/users/logout` }).withAuth();

export const forgetPasswordInitateApi = (forgetPasswordParams) =>
  callApi({
    method: 'POST',
    url: `/users/forgetPassword`,
    body: { ...forgetPasswordParams }
  }).anonymous();

export const forgetPasswordCompleteApi = (userParams) =>
  callApi({
    method: 'POST',
    url: `/users/forgetPassword/completed`,
    body: { ...userParams.password }
  }).anonymous();

export const resendOtpApi = (otpParam) =>
  callApi({
    method: 'GET',
    url: `/resendOTP?id=${otpParam}`
  }).anonymous();

export const testOtpApi = (otpParams) =>
  callApi({
    method: 'POST',
    url: `/Test/getOtp`,
    body: { ...otpParams }
  }).anonymous();
