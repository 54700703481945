import produce from 'immer';
import * as Types from 'views/common/redux/types';

const authInitialState = {};
const auth = produce((state, action) => {
  switch (action?.type) {
    case Types.SET_AUTH_DATA:
      state.data = action.payload;
      return state;
    case Types.SET_AUTHSTUDENTID_DATA:
      state.data.StudentId = action.payload;
      return state;
    case Types.SET_AUTHSTAFFID_DATA:
      state.data.StaffId = action.payload;
      return state;
    default:
      return state;
  }
}, authInitialState);

export { auth };
