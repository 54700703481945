import { combineReducers } from 'redux';
import produce from 'immer';
import { connectRouter } from 'connected-react-router';
import * as UserReducers from 'views/user/redux/reducers';
import * as ExamReducers from 'views/Exam/redux/reducers';
import * as UploadReducers from 'views/Upload/redux/reducers';
import * as CreateTestReducers from 'views/CreateTest/redux/reducers';
import * as CommonReducers from 'views/common/redux/reducers';
import * as ProfileReducers from 'views/profile/redux/reducers';
import * as SubscriptionReducers from 'views/Subscription/redux/reducers';
import * as HistoryReducers from 'views/History/redux/reducers';
import * as DashboardReducers from 'views/Dashboard/redux/reducers';
import * as CommonTestReducers from 'views/CommonTest/redux/reducers';
import * as CourseReducers from 'views/Course/redux/reducers';
import * as BulkImportReducers from 'views/BulkImport/redux/reducers';
import * as QuestionReducers from 'views/Question/redux/reducers';
import { themes } from '@helpers/components/NaviraTheme/themes/themes';
import { createBrowserHistory } from 'history';
import * as CommonTypes from './types';

export const history = createBrowserHistory();

const DEFAULT_ALERT_STATE = { show: false, data: null };
const naviraAlert = produce((state, action) => {
  switch (action?.type) {
    case CommonTypes.SHOW_N_HIDE_ALERT:
      state.data = action.payload.data;
      state.type = action.payload.type;
      state.show = action.payload.show;
      return;
    default:
      return state;
  }
}, DEFAULT_ALERT_STATE);

const DEFAULT_DIALOG_STATE = { dialogs: [] };
const naviraDialog = produce((state, action) => {
  switch (action?.type) {
    case CommonTypes.SHOW_CUSTOM_DIALOG:
      state.dialogs = [...state.dialogs, action.payload];
      return;

    case CommonTypes.HIDE_CUSTOM_DIALOG:
      state.dialogs = [...state.dialogs?.filter((each) => each !== action.payload)];
      return;

    default:
      return state;
  }
}, DEFAULT_DIALOG_STATE);

const DEFAULT_STATE = { loading: false };
const showLoading = produce((state, action) => {
  switch (action?.type) {
    case CommonTypes.LOADING:
      state.loading = action.payload;
      return;
    default:
      return state;
  }
}, DEFAULT_STATE);
const DEFAULT_ERROR_STATE = {};
const errorResponse = produce((state, action) => {
  switch (action?.type) {
    case CommonTypes.ERROR_RESPONSE:
      state = { ...action.payload };
      return;
    default:
      return state;
  }
}, DEFAULT_ERROR_STATE);

const APP_THEME = { isDarkTheme: false, ...themes };
const appTheme = produce((state, action) => {
  switch (action?.type) {
    case CommonTypes.SET_THEME:
      state.isDarkTheme = action.payload;
      return;
    default:
      return state;
  }
}, APP_THEME);

const DEFAULT_COMMON_STATE = { isUserAuthorized: undefined };
const common = produce((state, action) => {
  switch (action?.type) {
    case CommonTypes.IS_USER_AUTHORIZED:
      state.isUserAuthorized = action.payload;
      return;
    case CommonTypes.IS_SESSION_IDLE:
      state.isSessionIdle = action.payload;
      return;
    default:
      return state;
  }
}, DEFAULT_COMMON_STATE);

// eslint-disable-next-line no-unused-vars
const allReducers = combineReducers({
  router: connectRouter(history),
  appTheme,
  common,
  showLoading,
  errorResponse,
  naviraAlert,
  naviraDialog,
  ...UserReducers,
  ...ExamReducers,
  ...UploadReducers,
  ...CommonReducers,
  ...ProfileReducers,
  ...CreateTestReducers,
  ...SubscriptionReducers,
  ...HistoryReducers,
  ...DashboardReducers,
  ...CommonTestReducers,
  ...CourseReducers,
  ...BulkImportReducers,
  ...QuestionReducers
});

export const rootReducer = (state, action) => {
  if (action?.type === 'RESET_APP') {
    const { router } = state;
    state = { router };
  }
  return allReducers(state, action);
};
