import { lazy } from 'react';

export const QuestionConfig = {
  routes: [
    {
      path: '/upload',
      exact: true,
      component: lazy(() => import('./sections/uploadTab'))
    },
    {
      path: '/upload/addQuestion',
      exact: true,
      component: lazy(() => import('./sections/addQuestion'))
    },
    {
      path: '/upload/practice',
      exact: true,
      component: lazy(() => import('./sections/typingPractice'))
    },
    {
      path: '/upload/edit',
      exact: true,
      component: lazy(() => import('./sections/editQuestion'))
    },
    {
      path: '/upload/languageQuestions',
      exact: true,
      component: lazy(() => import('./sections/questionDetailsTab'))
    }
  ]
};
