import './App.css';
import { NaviraTheme, NaviraLayout, Auth } from '@helpers';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { appStore, history } from 'app/redux/store';
import { create } from 'jss';
import jssExtend from 'jss-extend';
import { StylesProvider, jssPreset, createGenerateClassName } from '@mui/styles';
import { HelmetProvider } from 'react-helmet-async';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName({ productionPrefix: 'navira' });

function App() {
  return (
    <HelmetProvider>
      <StylesProvider {...{ jss, generateClassName }}>
        <Provider store={appStore}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ConnectedRouter history={history}>
              <Auth>
                <NaviraTheme>
                  <NaviraLayout />
                </NaviraTheme>
              </Auth>
            </ConnectedRouter>
          </LocalizationProvider>
        </Provider>
      </StylesProvider>
    </HelmetProvider>
  );
}

export default App;
