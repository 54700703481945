import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDialog } from 'components';
import { logout } from 'views/user/redux/actions';
import * as CommonActions from 'app/redux/actions';
import { v4 } from 'uuid';
import { removeToken } from '@helpers/utilities/storageHelper';
import { auth } from 'views/common/redux/actions';
import { replace } from 'connected-react-router';
import { errorMapper } from '@helpers/utilities';

function SessionIdle() {
  const [dialogId, setDialogId] = useState(undefined);
  const userName = useSelector((state) => state?.auth?.data?.UserName ?? undefined);
  const isUserAuthorized = useSelector((state) => state?.common?.isUserAuthorized);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserAuthorized === false) {
      setDialogId(v4());
    }
  }, [isUserAuthorized]);

  const handleLogout = (e) => {
    dispatch(CommonActions.startLoading());
    const logoutResponse = logout.get();
    logoutResponse.subscribe(
      () => {
        removeToken();
        dispatch(auth.set(null));
        dispatch(CommonActions.stopLoading());
        dispatch(CommonActions.showAlert('Logged out successfully', 'success'));
        dispatch(replace(`/login?`));
      },
      (error) => {
        dispatch(CommonActions.errorResponse(error.response));
        dispatch(CommonActions.stopLoading());
        dispatch(CommonActions.showAlert(errorMapper(error.response), 'error'));
        dispatch(replace(`/login?`));
        e.preventDefault();
      }
    );
  };

  if (!dialogId) {
    return null;
  }

  return (
    <CustomDialog
      id={dialogId}
      disableEscKey
      disableBackDrop
      disableAutoFocus
      handlePrimaryButtonClick={handleLogout}
      dialogTitle="Session idle"
      maxWidth="sm"
      dialogContent={`Hi ${userName}, your session has been expired. Please login again to continue.`}
      buttonPrimaryText="Logout"
    />
  );
}

export default memo(SessionIdle);
