import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from './index';

const defaultOption = { label: 'None', value: -1 };
const OBJECT_TYPE = {
  course: 'course',
  duration: 'duration',
  test: 'test',
  student: 'student',
  client: 'client'
};

const optionMapper = (label, collection) => {
  const _defaultOption = { ...defaultOption, label };
  const defaultCollection = [_defaultOption];

  return (labelKey, valueKey) => {
    if (!collection) {
      return defaultCollection;
    }

    const mappedCollection = collection?.map((each) => ({
      label: each[labelKey],
      value: each[valueKey],
      ...each
    }));

    return [_defaultOption, ...mappedCollection];
  };
};

const mapSource = (type, pHolder, values) => {
  switch (type.toLowerCase()) {
    case OBJECT_TYPE.course:
      return optionMapper(pHolder, values)('name', 'id');
    case OBJECT_TYPE.duration:
      return optionMapper(pHolder, values)('label', 'value');
    case OBJECT_TYPE.test:
      return optionMapper(pHolder, values)('name', 'id');
    case OBJECT_TYPE.student:
      return optionMapper(pHolder, values)('emailId', 'studentId');
    case OBJECT_TYPE.client:
      return optionMapper(pHolder, values)('name', 'id');
    default:
      return optionMapper();
  }
};

function ObjectSelection(props) {
  const { label, placeholder, value, dropdownObject, type, onChange, multiple, ...other } = props;
  const [selectedValue, setSelectedValue] = useState(!multiple ? -1 : []);
  const [source, setSource] = useState([defaultOption]);

  useEffect(() => setSelectedValue(value), [value]);
  useEffect(() => setSource(mapSource(type, placeholder, dropdownObject)), [dropdownObject]);

  const handleDropdownChange = (e) => {
    const { value } = e?.target;
    setSelectedValue(value);
    if (onChange) onChange(e, !multiple ? source?.find((each) => each.value === value) : value);
  };

  return (
    <CustomSelect
      {...{
        label,
        variant: other.variant || 'outlined',
        placeholder,
        selectedValue,
        dropdownValues: !multiple ? source : source.filter((each) => each.value !== -1),
        fullWidth: true,
        onDropdownChange: handleDropdownChange,
        multiple,
        ...other
      }}
    />
  );
}

ObjectSelection.prototype = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired || PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(ObjectSelection);
export { OBJECT_TYPE };
